import { Link, useLocation } from "react-router-dom";
import ImagesGallery from "../Components/ImageGallary";

export default function DriverDetails() {
  const imgStyle = {
    height: "200px",
    borderRadius: "200px",
    margin: "auto",
    display: "flex",
    justiyContent: "center",
  };
  const location = useLocation();
  const row = location.state.row;
  const showMap = (lat, lng) => {
    window.open("https://maps.google.com?q=" + lat + "," + lng);
  };
  console.log(row);
  return (
    <>
      <div className="layout-spacing row">
        <div className="col-10">
          <h2 className="textOrange">تفاصيل السائق</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/drivers"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>

      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area rounded-pills-icon ">
            <ImagesGallery
              images={[
                {
                  images: row?.user?.profileImage,
                  description: " صورة المستخدم",
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area">
            <div className="col-10">
              <h4 className="textOrange">تفاصيل المستخدم </h4>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">الاسم</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.user?.fullName}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">البريد الالكتروني</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.user?.email}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">رقم الهاتف</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.user?.userName.replaceAll("+966", "0")}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">الحالة</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.driver?.statuse == null ? "غير مفعل" : "مفعل"}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">الجنس</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.user?.gender}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">رقم الأيبان</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.user?.ibanNumber}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">الجنسية</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.user?.country}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">المنطقة</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.user?.city}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">العمر</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.user?.age}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">إجمالي الطلبات</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.driver?.order_count}
                  disabled
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">التقييمات</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.driver?.rate}
                  disabled
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">المراجعات</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.driver?.reviews}
                  disabled
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">ملخص الاستعراضات</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.driver?.review_sum}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area rounded-pills-icon ">
            <ImagesGallery
              images={[
               
                {
                  images: row?.driver?.carImageBack,
                  description: " صورة السيارة من الخلف",
                },
                {
                  images: row?.driver?.carImageFront,
                  description: "صورة السيارة من الأمام",
                },
                {
                  images: row?.driver?.carlicenseImage,
                  description: "صورة رخصة السيارة",
                },
                {
                  images: row?.driver?.driverlicenseImage,
                  description: "صورة رخصة القيادة",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area">
            <div className="col-10">
              <h4 className="textOrange"> تفاصيل السيارة</h4>
            </div>
            <div className="row">
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">نوع السيارة</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.driver?.carType}
                  disabled
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">طراز السيارة</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.driver?.carModel}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <div className="item-title">رقم السياره</div>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.driver?.carNumber}
                  disabled
                />
              </div>
              <div className="col-xl-3 col-md-4 col-12 py-4">
                <button
                  onClick={(e) => showMap(row?.driver?.lat, row?.driver?.lng)}
                  className="item-content btn btn-outline-warning rounded-2 mt-4"
                >
                  موقع السائق &nbsp; &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-map-pin"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </svg>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}
