import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Components/preLoading";
export default class CusineMeals extends React.Component {
  componentDidMount() {
    this.getData();
  }
  pageCount = 10;
  constructor(props) {
    super(props);
    this.state = {
      city: "الكل",
      page: 1,
      filteredData: null,
    };
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  updateFood = async (status, id) => {
    const jsonRequest = {};
    var form = new FormData();
    form.append("id", id.toString());
    form.append("status", status.toString());

    // console.log(jsonRequest);
    try {
      const response = await fetch(window.baseurl + "food/update-status", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: form,
      });
      this.getData();
    } catch (error) {
      console.error(error);
    }
  };

  onPaginationChange(event, value) {
    this.setState({ page: value });
  }

  paginatedList() {
    return this.state.filteredData.slice(
      (this.state.page - 1) * this.pageCount,
      this.state.page * this.pageCount
    );
  }

  data = null;
  searchData(search) {
    var filtered =
      search == ""
        ? this.data
        : this.data.filter(
            (e) =>
              e.food.marketName.includes(search) || e.food.name.includes(search)
          );
    this.setState({ filteredData: filtered });
  }

  getData = async () => {
    try {
      const form = new FormData();
      // console.log(this.props.row);
      form.append(
        "marketId",
        this.props.row.provider
          ? this.props.row.provider.id
          : this.props.row.food.market_id
      );
      const response = await fetch(window.baseurl + "admin/market/detail", {
        method: "Post",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: form,
      });
      const json = await response.json();
      // console.log(json);
      this.data = json.foods;
      this.searchData("");
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <>
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div className="widget-content widget-content-area br-6">
                <div className="row">
                  <div className="col-md-8">
                    <label asp-for="Name"> بحث</label>
                    <input
                      type="text"
                      onChange={(e) => this.searchData(e.target.value)}
                      className="form-control"
                      id="name"
                      placeholder=" الاسم ، رقم الهاتف ، الايميل"
                    />
                  </div>
                </div>

                <div className="table-responsive mb-4 mt-4">
                  {this.data == null ? (
                    <Loading />
                  ) : (
                    <div>
                      <table
                        id="zero-config"
                        className="table table-hover filesTable"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">م</th>
                            <th scope="col">اسم الوجية</th>
                            <th scope="col">السعر</th>
                            <th scope="col">الصورة</th>
                            <th scope="col">مفعل</th>
                            <th scope="col">إجراء</th>
                          </tr>
                        </thead>
                        {this.paginatedList().map((e, index) => (
                        <tbody key={index}>
                            <tr>
                              <td>{this.state.filteredData.indexOf(e) + 1}</td>
                              <td>{e?.food.name.split("大")[0]}</td>
                              <td>{e?.food.price + " SR"}</td>
                              <td>
                                <div className="d-flex">
                                  <div className="usr-img-frame mr-2 rounded-circle">
                                    {
                                      <img
                                        className="img-fluid rounded-circle"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={
                                          window.baseurl +
                                          "uploads/" +
                                          e.photos[0].url
                                        }
                                        title={
                                          e?.provider?.title.split("大")[0]
                                        }
                                        onError={(e) => (
                                          (e.target.onerror = null),
                                          (e.target.src = "../img/account.jpeg")
                                        )}
                                        alt="img"
                                      />
                                    }
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  onClick={(ev) => {
                                    var status = e.food.status === 0 ? 1 : 0;
                                    // console.log(status);
                                    this.updateFood(status, e.food.id);
                                  }}
                                  className="row cursor-pointer"
                                >
                                  <h6
                                    className={
                                      e.food.status == 0 ? "pend" : "publish"
                                    }
                                  >
                                    مفعل
                                  </h6>
                                  <h6
                                    className={
                                      e.food.status == 1 ? "pend" : "disable"
                                    }
                                  >
                                    غير مفعل
                                  </h6>
                                </div>
                              </td>
                              <td>
                                <Link
                                  to={"/admin/food/detail"}
                                  state={{
                                    row: e,
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="blue"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-eye"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </Link>
                              </td>
                            </tr>
                        </tbody>
                          ))}
                        <tfoot>
                          <tr>
                            <th scope="col">م</th>
                            <th scope="col">اسم الوجية</th>
                            <th scope="col">السعر</th>
                            <th scope="col">الصورة</th>
                            <th scope="col">مفعل</th>
                            <th scope="col">إجراء</th>
                          </tr>
                        </tfoot>
                      </table>
                      <div dir="ltr" className="mx-auto mt-[40px]">
                        <Pagination
                          count={Math.ceil(
                            this.state.filteredData.length / this.pageCount
                          )}
                          onChange={this.onPaginationChange}
                          variant="outlined"
                          color="primary"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
