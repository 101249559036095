import React from "react";
import "./Login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var userName;
var password;
export default function Login() {
  let [errorUserName, setErrorUserName] = React.useState();
  const results = (e) => {
    if (e === "OK") {
      toast.success(
        "Thank you for your message. We will get back to you as soon as possible.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (e === "inputNull") {
      toast.error("أدخل البريد الإلكتروني وكلمة المرور", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "userName") {
      setErrorUserName="اكتب اسم المستخدم";
      toast.error("اكتب اسم المستخدم", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "password") {
      toast.error("اكتب كلمة المرور", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "WrongCredentials") {
      toast.error("الرجاء إدخال البريد الإلكتروني الصحيح وكلمة المرور", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const login = async () => {
    //console.log(userName + "  " + password);
    if (!userName && !password) {
      results("inputNull");
      return;
    }
    if (!userName) {
      results("userName");
      return;
    }
    if (!password) {
      results("password");
      return;
    }
    try {
      const response = await fetch(window.baseurl + "auth/admin-login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userName: userName,
          password: password,
        }),
      });
      const json = await response.json();
      //console.log("fdfjhdf  ", response.error);

      //console.log(json.message);
      if (json.token) {
        localStorage.setItem("token", json.token);
        localStorage.setItem("id", json.user.id);
        localStorage.setItem("email", json.user.email);
        //console.log(json.user.email);
        localStorage.setItem("role", json.user.role);
        localStorage.setItem("name", json.user.fullName);
        localStorage.setItem("roles", json.user.roles);
        //console.log(json.user.roles);
        window.location.href = "/admin";
      } else {
        results("WrongCredentials");
      }

      //console.log(json.response);
      if (json.response === "OK") {
        results("OK");
      } else {
        results("error");
      }
      return json;
    } catch (error) {
      results(error);
    }
  };

  return (
    <div className="form-container">
      <div className="form-form">
        <div className="form-form-wrap">
          <div className="form-container">
            <div className="form-content">
              <h1 className="">
                تسجيل الدخول إلى{"  "}
                <a href="index.html">
                  <span className="brand-name">مطبخ ٢٤/٧</span>
                </a>
              </h1>
              <div className="text-left">
                <form className="">
                  <div className="form">
                    <div id="username-field" className="field-wrapper input">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-at-sign"
                      >
                        <circle cx="12" cy="12" r="4"></circle>
                        <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                      </svg>
                      <input
                        id="username"
                        name="username"
                        type="text"
                        className="form-control"
                        placeholder="البريد الإلكتروني"
                        onChange={(e) =>
                          e.target.value !== undefined
                            ? (userName = e.target.value)
                            : console.log("")
                        }
                      />
                      <span className="text-danger">{errorUserName}</span>
                    </div>

                    <div
                      id="password-field"
                      className="field-wrapper input mb-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-lock"
                      >
                        <rect
                          x="3"
                          y="11"
                          width="18"
                          height="11"
                          rx="2"
                          ry="2"
                        ></rect>
                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                      </svg>
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="off"
                        className="form-control"
                        required
                        placeholder="كلمه السر"
                        onChange={(e) =>
                          e.target.value !== undefined
                            ? (password = e.target.value)
                            : console.log("")
                        }
                      />
                    </div>
                    <div className="d-sm-flex justify-content-between">
                      <div className="field-wrapper toggle-pass">
                        <label className="switch s-primary">
                          <input
                            type="checkbox"
                            id="toggle-password"
                            className="d-none"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="field-wrapper"></div>
                    </div>
                  </div>
                </form>

                <button
                  onClick={login}
                  type="submit"
                  className="btn btn-primary"
                  value=""
                >
                  تسجيل الدخول
                </button>
                <ToastContainer />
              </div>
              <p className="terms-conditions">
                جميع الحقوق محفوظة.<a href="/">مطبخ ٢٤/٧</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="form-image">
        <div className="l-image"></div>
      </div>
    </div>
  );
}
