import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
var city = "الجبيل";
export default function AddAdmin() {
  let navigate = useNavigate();

  const postForm = async (event) => {
    event.preventDefault();
    const formdata = new FormData(event.target);

    formdata.append("knownName", "admin");
    formdata.append("bank", "ahli");
    formdata.append("userName", formdata.get("email"));
    formdata.append("role", "admin");
    formdata.append("city", "-");
    formdata.append("identityNumber", "-");
    formdata.append("ProfileImage", "account.jpeg");

    try {
      const response = await fetch(window.baseurl + "auth/admin-signup", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
      });
      const json = await response.json();
      //console.log(json);
      navigate("/admin/admins");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    
      <div className="layout-spacing row">
        <div className="col-10">
          <h2 className="textOrange">اضافة مدير</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/admins"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>

      <div id="flLoginForm" className="col-lg-12 layout-spacing rounded">
        <div className="statbox">
          <div className="widget-content widget-content-area">
            <form onSubmit={postForm}>
              <div className="form-group mb-4">
                <label>
                  <span className="text-danger">*</span> الاسم بالكامل
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="fullName"
                  id="fullName"
                  required
                  placeholder=" الاسم بالكامل"
                />
              </div>
              <div className="form-group mb-4">
                <label>
                  <span className="text-danger">*</span>البريد الالكتروني
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  id="email"
                  required
                  placeholder="البريد الالكتروني"
                />
              </div>
              <div className="form-group">
                <label>
                  <span className="text-danger">*</span>كلمة المرور
                </label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  required
                  id="password"
                  placeholder=" كلمة المرور"
                />
              </div>
              <span id="emailHelp" className="form-text text-danger">
                *الحقول المطلوبة
              </span>
              <Link to="/admin/admins" className="btn btn-danger mt-4 floated">
                إلغاء
              </Link>

              <button
                onClick={(e) => {
                  postForm();
                }}
                type="submit"
                className="btn btn-success col-2 mt-4 floated"
              >
                إنشاء الحساب{" "}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
