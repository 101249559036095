import { Link, useLocation } from "react-router-dom";
import ImagesGallery from "../Components/ImageGallary";

export default function AccountDetails() {
  const imgStyle = { height: "100px", borderRadius: "100px", margin: "auto" };
  const location = useLocation();
  const row = location.state.row;
  //console.log(row);
  return (
    <>
      <div className="layout-spacing row">
        <div className="col-10">
          <h2 className="textOrange">تفاصيل السائق</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/accounts"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>

      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area rounded-pills-icon ">
            <ImagesGallery images={
              [
                {
                  images: row?.profileImage,
                  description: "صورة ملف تعريف المستخدم",
                },
                {
                  images: row?.banarImage,
                  description: "صورة لافتة المستخدم"  ,
                },
                ]} />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area">
            {
              //row?.emailConfirmed === false ? <p className="badge badge-danger col-12" >
              row?.emailConfirmed !== false ? (
                <p className="badge badge-danger col-12">
                  لم يتم التحقق من البريد الإلكتروني للمستخدم
                </p>
              ) : (
                ""
              )
            }

            <div className="row">
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">الاسم</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.fullName || ""}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">البريد الالكتروني</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.email || ""}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">رقم الهاتف</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.userName.replaceAll("+", "") || ""}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">تاريخ التسجيل</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={
                    row?.createdAt.split("T")[1] +
                    " | " +
                    row?.createdAt.split("T")[0]
                  }
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">الحالة</h5>
                &nbsp;
                <input
                  className={
                    row?.statuse == 0
                      ? "form-control disabled-form-control text-danger bg-danger"
                      : "form-control disabled-form-control"
                  }
                  value={row?.statuse == 0 ? "غير مفعل" : "مفعل" || ""}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">الجنس</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.gender == "mail" ? "الرجال" : "النساء" || ""}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">رقم الأيبان</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.ibanNumber || "لا يوجد"}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">الجنسية</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.country || "لا يوجد"}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">المنطقة</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.city || "لا يوجد"}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">العمر</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.age || "لا يوجد"}
                  disabled
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">
                  {row?.role == "driver" ? "نوع السيارة" : ""}
                </h5>
                &nbsp;
                <input
                  className={
                    row?.role == "driver"
                      ? "form-control disabled-form-control"
                      : "invisible"
                  }
                  value={row?.carType || "لا يوجد"}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
