import { useLocation, Link } from "react-router-dom";
import ImagesGallery from "../Components/ImageGallary";

export default function ProviderDetails() {
  const location = useLocation();
  const row = location.state.row;
  console.log(row);
  const showMap = (lat, lng) => {
    window.open("https://maps.google.com?q=" + lat + "," + lng);
  };
  return (
    <>
      <div className="layout-spacing row">
        <div className="col-10">
          <h2 className="textOrange">تفاصيل المطبخ</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/providers"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>

      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area rounded-pills-icon ">
            <ImagesGallery
              images={[
                {
                  images: row?.user?.profileImage,
                  description: "صورة ملف تعريف المستخدم",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area">
            <div className="col-10">
              <h4 className="textOrange">تفاصيل المستخدم </h4>
            </div>
            <div className="row">
              <div className="col-md-6 py-2">
                <div className="item-title">الاسم</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.fullName.split("大")[0]}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">البريد الالكتروني</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.email}
                    disabled
                  />
                </div>
              </div>

              <>  {/*<div className="col-md-6 py-2">
                <div className="item-title">البريد الالكتروني</div>
                <div className="item-content row">
                  <div className="col-11">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={row?.user?.email}
                      disabled
                    />
                  </div>
                   <div
                    className="col-1 container justify-center"
                    title={
                      row?.user?.emailConfirmed === true
                        ? "تم تأكيد البريد الإلكتروني"
                        : " لم يتم تأكيد البريد الإلكتروني"
                    }
                  >
                    {row?.user?.emailConfirmed === true ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="success"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-check-circle"
                      >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="red"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-alert-circle"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                      </svg>
                    )}
                  </div> 
                </div>
              </div>*/}
              </>

              <div className="col-md-6 py-2">
                <div className="item-title">رقم الهاتف</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.userName.replaceAll("+", "")}
                    disabled
                  />
                </div>
              </div>

              <>
                {/*<div className="col-md-6 py-2">
                <div className="item-title">رقم الهاتف</div>
                <div className="item-content row">
                  <div className=" col-11">
                    <input
                      className="form-control"
                      type="text"
                      placeholder={row?.user?.userName.replaceAll("+", "")}
                      disabled
                    />
                  </div>
                  <div
                    className="col-1 flex justify-center"
                    title={
                      row?.user?.phoneNumberConfirmed === true
                        ? "تم تأكيد اسم المستخدم"
                        : " لم يتم تأكيد اسم المستخدم"
                    }
                  >
                    {row?.user?.phoneNumberConfirmed === true ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="success"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-check-circle align-bottom"
                      >
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="red"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-alert-circle  align-bottom"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="12"></line>
                        <line x1="12" y1="16" x2="12.01" y2="16"></line>
                      </svg>
                    )}
                  </div> 
                </div>
              </div>
              */}
              </>
              <div className="col-md-6 py-2">
                <div className="item-title">الجنس</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.gender}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">تاريخ التسجيل</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={
                      row?.user?.createdAt.split("T")[0] +
                      "   " +
                      row?.user?.createdAt.split("T")[1]
                    }
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">عمر</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.age}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">الجنسية</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.country}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 py-2">
                <div className="item-title">مدينة</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.city}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">اسم البنك</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.bank}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">رقم الأيبان</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.user?.ibanNumber}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area rounded-pills-icon ">
            <ImagesGallery
              images={[
                {
                  images: row?.provider?.image,
                  description: "صورة المطبخ",
                },
                {
                  images: row?.provider?.banarImage,
                  description: "المطبخ راية الصورة",
                },
                {
                  images: row?.provider?.idCertificate,
                  description: "شهادة المطبخ",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area">
            <div className="col-10">
              <h4 className="textOrange"> تفاصيل المطبخ </h4>
            </div>
            <div className="row">
              <div className="col-md-6 py-2">
                <div className="item-title">إجمالي الطلبات</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.provider?.order_count}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title"> التقييمات</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.provider?.rate}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">المراجعات</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.provider?.reviews}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">ملخص الاستعراضات</div>
                <div className="item-content">
                  <input
                    className="form-control"
                    type="text"
                    placeholder={row?.provider?.review_sum}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">ملخص</div>
                <div className="item-content">
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder={row?.provider?.summary}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <div className="item-title">موقع المزود</div>
                <div className="item-content">
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder={row?.provider?.address}
                    disabled
                  />
                </div>
              </div>

              <div className="col-md-6 py-2">
                <button
                  onClick={(e) => showMap(row?.provider?.lat, row?.driver?.lng)}
                  className="item-content btn btn-outline-warning rounded-2 mt-4"
                >
                  موقع العميل &nbsp; &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-map-pin"
                  >
                    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                    <circle cx="12" cy="10" r="3"></circle>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
