import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import SideMenu from "../Components/sideNav";

export default class Dashboard extends React.Component {
  componentDidMount() {}
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="sub-header-container">
          <header className="header navbar navbar-expand-sm">
            <a
               href="#!" 
               onClick={e => e.preventDefault()}
              className="sidebarCollapse border-0 bg-transparent"
              data-placement="bottom"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
            <ul className="navbar-nav flex-row">
              <li>
                <div className="page-header">
                  <nav className="breadcrumb-one" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <h4>مطبخ ٢٤/٧</h4>
                      </li>
                    </ol>
                  </nav>
                </div>
              </li>
            </ul>
          </header>
        </div>

        <div className="main-container" id="container">
          <div className="overlay"></div>
          <div className="search-overlay"></div>
          <SideMenu />
          <div id="content" className="main-content">
            <div className="layout-px-spacing">
              <Outlet />
            </div>
          </div>
        </div>
      </>
    );
  }
}
