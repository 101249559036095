import React from "react";

export default function preLoading() {
  return (
    <div id="load_screen">
      <div className="loader">
        <div className="loader-content">
          <div className="spinner-grow align-self-center"></div>
        </div>
      </div>
    </div>
  );
}
