import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Components/preLoading";
import { Pagination } from "@mui/material";

export default class Transactions extends React.Component {
  pageCount = 10;
  onPaginationChange(event, value) {
    this.setState({ page: value });
  }
  paginatedList() {
    return this.state.filteredData.slice(
      (this.state.page - 1) * this.pageCount,
      this.state.page * this.pageCount
    );
  }
  
  componentDidMount() {
    this.getData();
  }

  data = null;
  filterData(status) {
    this.state.filteredData =
    status === "الكل"
        ? this.data
        : this.data.filter((e) => this.orderStatus[e.order?.status] === status);
    return this.filteredData;
  }

  searchData(search) {
    this.state.filteredData =
      search === "" ? this.data : this.data.filter((e) => e.order?.id === search);
    this.setState({});
  }

  constructor(props) {
    super(props);
    this.state = {
      status: "الكل",
      page: 1,

      filteredData: null,
    };
    //this.handleChange = this.handleChange.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  getData = async () => {
    try {
      const response = await fetch(window.baseurl + "admin/transaction", {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      this.data = json;
      this.filterData("الكل");
      this.setState({});

      return json;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <>
        <div className="py-4 px-5">
          <h1> التدقيق المالي</h1>
        </div>
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div className="widget-content widget-content-area br-6">
                <div className="row">
                  <div className="col-md-6 row">
                    <label asp-for="Name"> بحث</label>
                    <input
                      type="text"
                      onChange={(e) => this.searchData(e.target.value)}
                      className="form-control"
                      id="name"
                      placeholder="رقم الأمر"
                    />
                  </div>
                </div>

                <div className="table-responsive mb-4 mt-4">
                  {this.state.filteredData == null ? (
                    <Loading />
                  ) : (
                    <div>
                      <table
                        id="zero-config"
                        className="table table-hover"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="Gold"
                                stroke="Gold"
                                strokeWidth="0"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-star"
                              >
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                              </svg>
                            </th>
                            <th scope="col">م</th>
                            <th scope="col">رقم الأمر</th>
                            <th scope="col">تاريخ</th>
                            <th scope="col">الوقت</th>
                            <th scope="col">السعر الكلي</th>
                            <th scope="col">تفاصيل</th>
                          </tr>
                        </thead>
                        {this.paginatedList().map((e, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="Gold"
                                  stroke="Gold"
                                  strokeWidth="0"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </td>
                              <td>{this.state.filteredData.indexOf(e) + 1}</td>
                              <td>{e?.order?.id}</td>
                              <td>{e?.order?.date.split("T")[0]}</td>
                              <td>{e?.order?.date.split("T")[1]}</td>
                              <td>{e?.order?.price}</td>
                              <td>
                                <Link
                                  className="btn btn-outline-primary rounded-circle mx-2"
                                  to={"/admin/transaction/Detail"}
                                  state={{
                                    row: e,
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-eye"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                        <tfoot>
                          <tr>
                            <th>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="Gold"
                                stroke="Gold"
                                strokeWidth="0"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-star"
                              >
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                              </svg>
                            </th>
                            <th scope="col">م</th>
                            <th scope="col">رقم الأمر</th>
                            <th scope="col">تاريخ</th>
                            <th scope="col">الوقت</th>
                            <th scope="col">السعر الكلي</th>
                            <th scope="col">تفاصيل</th>
                          </tr>
                        </tfoot>
                      </table>
                      <div dir="ltr" className="mx-auto mt-[40px]">
                        <Pagination
                          count={Math.ceil(
                            this.state.filteredData.length / this.pageCount
                          )}
                          onChange={this.onPaginationChange}
                          variant="outlined"
                          color="primary"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
