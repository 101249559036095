import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
const MultiSelectAll = ({ handleChange, data }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  //   useEffect(() => {
  //     setSelectedOptions([{ label: " الكل", value: "*" }, ...data]);
  //   }, []);




  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: الكل`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} عنصر`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
      handleChange(this.options)
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      handleChange([])

    } else if (event.action === "deselect-option") {
      handleChange(value.filter((o) => o.value !== "*"))

      this.setState(value.filter((o) => o.value !== "*"));

    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
      handleChange(this.options)

    } else {
      this.setState(value);
      handleChange(value)

    }

  }

  return (

    <ReactMultiSelectCheckboxes
      options={[{ label: "الكل", value: "*" }, ...data]}
      placeholderButtonLabel="اختر"
      placeholder={"بحث"}

      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions}

      onChange={onChange}
      setState={setSelectedOptions}
    />
  );
};

export default MultiSelectAll;
