import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Components/preLoading";
import { Pagination } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Blogs extends React.Component {
    componentDidMount() {
        this.getData();
    }
    pageCount = 10;
    search = "";

    DeleteAccount = async (e) => {
        // console.log(e);
        const formData = new FormData();
        formData.append("UserId", e);
        try {
            const response = await fetch(window.baseurl + "account/delete", {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
                body: formData,
            });
            const json = await response.json();
            this.data = json;
            if (response.ok) {
                toast.success("تم حذف المستخدم بنجاح", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            this.getData();
            // console.log(json);
            return json;
        } catch (error) {
            console.error(error);
        }
    };

    onSubmit = (e) => {
        confirmAlert({
            title: "تأكيد للحذف",
            message: "سيتم حذف المستخدم بشكل دائم",
            buttons: [
                {
                    label: "نعم ، احذف",
                    onClick: () => this.DeleteAccount(e),
                },
                {
                    label: "رقم",
                },
            ],
        });
    };

    constructor(props) {
        super(props);
        this.state = {
            city: "الكل",
            page: 1,
            filteredData: null,
        };
        this.handleChange = this.handleChange.bind(this);

        this.onPaginationChange = this.onPaginationChange.bind(this);
    }

    onPaginationChange(event, value) {
        this.setState({ page: value });
    }

    paginatedList() {
        return this.state.filteredData.slice(
            (this.state.page - 1) * this.pageCount,
            this.state.page * this.pageCount
        );
    }
    getData = async () => {
        try {
            const response = await fetch(window.baseurl + "api/blog", {
                method: "Get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            const json = await response.json();
            console.log(json);
            this.data = json;
            this.searchData(this.search);
            return json;
        } catch (error) {
            console.error(error);
        }
    };
    data = null;
    searchData(search) {
        this.state.filteredData =
            search === ""
                ? this.data
                : this.data.filter(
                    (e) => e?.title.includes(search) || e?.desc.includes(search)
                );
        this.setState({});
    }

    handleChange(e) {
        // console.log("Fruit Selected!!");
        this.filterData(e.target.value);
        this.setState({ city: e.target.value });
    }

    render() {
        return (
            <>
                <ToastContainer />
                <div className="py-4 px-5">
                    <h1>قائمة السائقين</h1>
                </div>

                <div className="layout-px-spacing">
                    <div className="row layout-top-spacing">
                        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
                            <div className="widget-content widget-content-area br-6">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                        <label asp-for="Name"> بحث</label>
                                        <input
                                            type="text"
                                            onChange={(e) => this.searchData(e.target.value)}
                                            className="form-control"
                                            id="name"
                                            placeholder=" الاسم ، رقم الهاتف ، الايميل"
                                        />
                                    </div>
                                </div>
                                <div className="table-responsive mb-4 mt-4">
                                    {this.data === null ? (
                                        <Loading />
                                    ) : (
                                        <div>
                                            <table
                                                id="zero-config"
                                                className="table table-hover filesTable"
                                                style={{ width: "100%" }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="Gold"
                                                                stroke="Gold"
                                                                strokeWidth="0"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                className="feather feather-star"
                                                            >
                                                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                                            </svg>
                                                        </th>
                                                        <th scope="col">م</th>
                                                        <th scope="col">تاريخ</th>
                                                        <th scope="col">عنوان</th>
                                                        <th scope="col">وصف</th>
                                                        <th scope="col">الصورة</th>
                                                        {/* <th scope="col">الحالة</th> */}
                                                        <th scope="col">تفاصيل</th>
                                                    </tr>
                                                </thead>
                                                {this.paginatedList().map((Blog, index) => (
                                                    <tbody key={index}>
                                                        <tr>
                                                            <td>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="Gold"
                                                                    stroke="Gold"
                                                                    strokeWidth="0"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-star"
                                                                >
                                                                    <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                                                </svg>
                                                            </td>
                                                            <td>
                                                                {this.state.filteredData.indexOf(Blog) + 1}
                                                            </td>
                                                            <td>
                                                                {Blog?.createdAt.split("T")[1]}
                                                                <br />
                                                                {Blog?.createdAt.split("T")[0]}
                                                            </td>
                                                            <td>{Blog?.title}</td>
                                                            <td>{Blog?.desc}</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <div className="usr-img-frame mr-2 rounded-circle">
                                                                        {
                                                                            <img
                                                                                className="img-fluid rounded-circle"
                                                                                style={{
                                                                                    width: "30px",
                                                                                    height: "30px",
                                                                                }}
                                                                                src={
                                                                                    window.baseurl +
                                                                                    "uploads/" +
                                                                                    Blog?.url
                                                                                }
                                                                                title={Blog?.title}
                                                                                onError={(e) => (
                                                                                    (e.target.onError = null),
                                                                                    ((e.target.src =
                                                                                        "../img/no-image.png"),
                                                                                        (e.target.title = "Image not found"))
                                                                                )}
                                                                                alt="img"
                                                                            />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <Link
                                                                    className="btn btn-outline-warning rounded-circle mx-2"
                                                                    to={"/admin/Blog/detail"}
                                                                    state={{
                                                                        row: Blog,
                                                                    }}
                                                                    title="حرر"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-edit-2"
                                                                    >
                                                                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                                                                    </svg>
                                                                </Link>
                                                                <Link
                                                                    className="btn btn-outline-primary rounded-circle mx-2"
                                                                    to={"/admin/Blog/detail"}
                                                                    state={{
                                                                        row: Blog,
                                                                    }}
                                                                    title="التفاصيل"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-eye"
                                                                    >
                                                                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                                        <circle cx="12" cy="12" r="3"></circle>
                                                                    </svg>
                                                                </Link>

                                                                <button
                                                                    className={
                                                                        localStorage.getItem("role") == "admin"
                                                                            ? "btn btn-outline-danger rounded-circle mx-2"
                                                                            : "invisible"
                                                                    }
                                                                    onClick={() => this.onSubmit(Blog?.id)}
                                                                    title="حذف"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="24"
                                                                        height="24"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        className="feather feather-trash"
                                                                    >
                                                                        <polyline points="3 6 5 6 21 6"></polyline>
                                                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                                <tfoot>
                                                    <tr>
                                                        <th scope="col">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="Gold"
                                                                stroke="Gold"
                                                                strokeWidth="0"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                className="feather feather-star"
                                                            >
                                                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                                            </svg>
                                                        </th>
                                                        <th scope="col">م</th>
                                                        <th scope="col">تاريخ</th>
                                                        <th scope="col">عنوان</th>
                                                        <th scope="col">وصف</th>
                                                        <th scope="col">الصورة</th>
                                                        {/* <th scope="col">الحالة</th> */}
                                                        <th scope="col">تفاصيل</th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                            <div dir="ltr" className="mx-auto mt-[40px]">
                                                <Pagination
                                                    count={Math.ceil(
                                                        this.state.filteredData.length / this.pageCount
                                                    )}
                                                    onChange={this.onPaginationChange}
                                                    variant="outlined"
                                                    color="primary"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
