import { Link, useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import React from "react";
import hasRole from "../Functions";
export default function SideMenu() {
  let navigate = useNavigate();
  let location = useLocation();
  function isActive(route) {
    return location.pathname == route ? "true" : "false";
  }
  function userLogin() {
    if (localStorage.getItem("token") == null) {
      window.location.href = "/login";
    }
  }
  userLogin();
  return (
    <>
      <div className="sidebar-wrapper sidebar-theme shadow">
        <nav id="sidebar">
          <ul className="list-unstyled menu-categories" id="accordionExample">
            {/* Dashboard */}
            <li className="menu border-bottom">
              <div className="sidebar-header">
                <div className="user-pic">
                  <img
                    width="24"
                    height="24"
                    src="https://i1.wp.com/www.nabd.dk/wp-content/uploads/2020/04/avatar-png-1.png?ssl=1"
                  />
                </div>
                <div className="user-info">
                  <span className="user-status">
                    <span>🟢 متصل </span>
                  </span>
                  <span className="user-name">
                    <strong>{localStorage.getItem("name")}</strong>
                  </span>
                  <span className="user-role">
                    {localStorage.getItem("role")}
                  </span>
                </div>
              </div>
            </li>
            <li className="menu">
              {hasRole("home") && (
                <button
                  onClick={(e) => navigate("/admin")}
                  className="dropdown-toggle"
                  data-active={isActive("/admin")}
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                    <span>لوحة القيادة</span>
                  </div>
                </button>
              )}
            </li>

            {/* admins */}
            <li className="menu">
              {hasRole("managers") && (
                <button
                  onClick={(e) => navigate("/admin/admins")}
                  className="dropdown-toggle"
                  data-active={
                    isActive("/admin/admins") == "true"
                      ? "true"
                      : isActive("/admin/roles") == "true"
                        ? "true"
                        : isActive("/admin/admin/add") == "true"
                          ? "true"
                          : "false"
                  }
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-users"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span>مدراء</span>
                  </div>
                </button>
              )}
            </li>

            {/* accounts */}
            <li className="menu">
              {hasRole("accounts") && (
                <button
                  onClick={(e) => navigate("/admin/accounts")}
                  data-active={
                    isActive("/admin/accounts") == "true"
                      ? "true"
                      : isActive("/admin/account/Detail") == "true"
                        ? "true"
                        : "false"
                  }
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-users"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span>المستخدمين</span>
                  </div>
                </button>
              )}
            </li>

            {/* providers */}
            <li className="menu">
              {hasRole("providers") && (
                <button
                  onClick={(e) => navigate("/admin/providers")}
                  data-active={
                    isActive("/admin/providers") == "true"
                      ? "true"
                      : isActive("/admin/provider/detail") == "true"
                        ? "true"
                        : isActive("/admin/Provider/Foods") == "true"
                          ? "true"
                          : "false"
                  }
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-box"
                    >
                      <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                      <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                      <line x1="12" y1="22.08" x2="12" y2="12"></line>
                    </svg>
                    <span>الطهاة</span>
                  </div>
                </button>
              )}
            </li>

            {/* drivers */}
            <li className="menu">
              {hasRole("drivers") && (
                <button
                  onClick={(e) => navigate("/admin/drivers")}
                  data-active={
                    isActive("/admin/drivers") == "true"
                      ? "true"
                      : isActive("/admin/driver/detail") == "true"
                        ? "true"
                        : "false"
                  }
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-zap"
                    >
                      <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
                    </svg>
                    <span>السائقين</span>
                  </div>
                </button>
              )}
            </li>

            {/* foods */}
            <li className="menu">
              {hasRole("foods") && (
                <button
                  onClick={(e) => navigate("/admin/foods")}
                  data-active={
                    isActive("/admin/foods") == "true"
                      ? "true"
                      : isActive("/admin/food/detail") == "true"
                        ? "true"
                        : "false"
                  }
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-clipboard"
                    >
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect
                        x="8"
                        y="2"
                        width="8"
                        height="4"
                        rx="1"
                        ry="1"
                      ></rect>
                    </svg>
                    <span>الطبخات</span>
                  </div>
                </button>
              )}
            </li>

            {/* Fields */}

            <li className="menu">
              {hasRole("fields") && (
                <button
                  onClick={(e) => navigate("/admin/fields")}
                  data-active={
                    isActive("/admin/fields") == "true"
                      ? "true"
                      : isActive("/admin/Field/Edit") == "true"
                        ? "true"
                        : isActive("/admin/field/add") == "true"
                          ? "true"
                          : "false"
                  }
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-list"
                    >
                      <line x1="8" y1="6" x2="21" y2="6"></line>
                      <line x1="8" y1="12" x2="21" y2="12"></line>
                      <line x1="8" y1="18" x2="21" y2="18"></line>
                      <line x1="3" y1="6" x2="3.01" y2="6"></line>
                      <line x1="3" y1="12" x2="3.01" y2="12"></line>
                      <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                    <span>مجالات</span>
                  </div>
                </button>
              )}
            </li>

            {/* Fields */}

            <li className="menu">
              {hasRole("notifications") && (
                <button
                  onClick={(e) => navigate("/admin/notifications")}
                  data-active={
                    isActive("/admin/notifications") == "true"
                      ? "true"
                      : "false"
                  }
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-bell"
                    >
                      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>
                    <span>إشعارات</span>
                  </div>
                </button>
              )}
            </li>

            {/* orders */}
            <li className="menu">
              {hasRole("orders") && (
                <button
                  onClick={(e) => navigate("/admin/orders")}
                  data-active={
                    isActive("/admin/orders") == "true"
                      ? "true"
                      : isActive("/admin/order/detail") == "true"
                        ? "true"
                        : "false"
                  }
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-clipboard"
                    >
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect
                        x="8"
                        y="2"
                        width="8"
                        height="4"
                        rx="1"
                        ry="1"
                      ></rect>
                    </svg>
                    <span>الطلبات</span>
                  </div>
                </button>
              )}
            </li>

            {/* reports */}
            <li className="menu">
              {hasRole("reports") && (
                <button
                  onClick={(e) => navigate("/admin/reports")}
                  data-active={isActive("/admin/reports")}
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-users"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <span>تقارير الطلبات </span>
                  </div>
                </button>
              )}
            </li>

            {/* transactions */}

            <li className="menu">
              {hasRole("transactions") && (
                <button
                  onClick={(e) => navigate("/admin/transactions")}
                  data-active={
                    isActive("/admin/transactions") == "true"
                      ? "true"
                      : isActive("/admin/transaction/Detail") == "true"
                        ? "true"
                        : "false"
                  }
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-credit-card"
                    >
                      <rect
                        x="1"
                        y="4"
                        width="22"
                        height="16"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="1" y1="10" x2="23" y2="10"></line>
                    </svg>
                    <span>التدقيق المالي </span>
                  </div>
                </button>
              )}
            </li>

            {/* settings */}
            <li className="menu">
              {hasRole("settings") && (
                <button
                  onClick={(e) => navigate("/admin/settings")}
                  data-active={isActive("/admin/settings")}
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-settings"
                    >
                      <circle cx="12" cy="12" r="3"></circle>
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                    </svg>
                    <span>الإعدادات</span>
                  </div>
                </button>
              )}
            </li>
            {/* support */}
            <li className={isMobile == false ? "menu" : "d-none"}>
              {hasRole("support") && (
                <button
                  onClick={(e) => navigate("/admin/support")}
                  data-active={isActive("/admin/support")}
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-message-square"
                    >
                      <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>
                    <span>الشكاوي </span>
                  </div>
                </button>
              )}
            </li>

            {/*
            <h3 className="textOrange">موقع إلكتروني</h3>

         
            <li className="menu">
              {hasRole("WebAlert") && (
                <button
                  onClick={(e) => navigate("/admin/website/alert")}
                  data-active={isActive("/admin/website/alert")}
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-settings"
                    >
                      <circle cx="12" cy="12" r="3"></circle>
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                    </svg>
                    <span>إرسال إخطار</span>
                  </div>
                </button>
              )}
            </li>

            
            <li className="menu">
              {hasRole("WebBlogs") && (
                <button
                  onClick={(e) => navigate("/admin/website/Blogs")}
                  data-active={
                    isActive("/admin/website/Blogs") == "true"
                      ? "true"
                      : isActive("/admin/website/Blog/Details") == "true"
                        ? "true"
                        : isActive("/admin/website/Blog/Add") == "true"
                          ? "true"
                          : isActive("/admin/website/Blog/Edit") == "true"
                            ? "true"
                            : "false"
                  }
                  className="dropdown-toggle"
                >
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-settings"
                    >
                      <circle cx="12" cy="12" r="3"></circle>
                      <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                    </svg>
                    <span> المدونات</span>
                  </div>
                </button>
              )}
            </li>
            */}
            {/* logout */}
            <li className="menu" onClick={(e) => localStorage.clear()}>
              <button
                onClick={(e) => navigate("/")}
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-lock"
                  >
                    <rect
                      x="3"
                      y="11"
                      width="18"
                      height="11"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                  </svg>
                  <span> تسجيل الخروج </span>
                </div>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
