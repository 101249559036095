import { Pagination } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../Components/preLoading";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default class Admins extends React.Component {
  componentDidMount() {
    this.getData();
  }

  DeleteAdmin = async (e) => {
    // console.log(e);
    const formData = new FormData();
    formData.append("UserId", e);
    try {
      const response = await fetch(window.baseurl + "account/delete", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formData,
      });
      const json = await response.json();
      this.data = json;
      if (response.ok == "true")
        response.json().then((json) => {
          this.getData();
          if (json.resultCode == "400") {
            toast.success("تم حذف المستخدم بنجاح", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });

      // console.log(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };
  onSubmit = (e) => {
    confirmAlert({
      title: "تأكيد للحذف",
      message: "سيتم حذف المستخدم بشكل دائم",
      buttons: [
        {
          label: "نعم ، احذف",
          onClick: () => this.DeleteAdmin(e),
        },
        {
          label: "رقم",
        },
      ],
    });
  };

  pageCount = 10;
  constructor(props) {
    super(props);
    this.state = {
      city: "الكل",
      id: "",
      page: 1,
      filteredData: null,
    };
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  onPaginationChange(event, value) {
    this.setState({ page: value });
  }

  paginatedList() {
    return this.state.filteredData.slice(
      (this.state.page - 1) * this.pageCount,
      this.state.page * this.pageCount
    );
  }

  data = null;
  searchData(search) {
    var filtered =
      search == ""
        ? this.data
        : this.data.filter(
            (e) =>
              e.email?.includes(search) ||
              e.userName?.includes(search) ||
              e.fullName?.includes(search)
          );
    this.setState({ filteredData: filtered });
  }

  getData = async () => {
    try {
      const response = await fetch(window.baseurl + "admin/get-admins", {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      console.log(json);
      this.data = json;
      this.searchData("");
      return json;
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    return (
      <>
        <ToastContainer />
        <div className="py-4 px-5">
          <h1>قائمة المسؤولين</h1>
        </div>
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div className="widget-content widget-content-area br-6">
            <div className="row flex-row">
              <div className="col-md-8">
                <label asp-for="Name"> بحث</label>
                <input
                  type="text"
                  onChange={(e) => this.searchData(e.target.value)}
                  className="form-control"
                  id="name"
                  placeholder=" الاسم ، رقم الهاتف ، الايميل"
                />
              </div>
              <div className="col-md-4">
                <Link
                  to={"/admin/admin/add"}
                  className="btn btn-warning btn-rounded mt-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-plus-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                  </svg>
                  &nbsp;&nbsp; إضافة&nbsp;&nbsp;
                </Link>
              </div>
            </div>

            <div className="table-responsive mb-4 mt-4">
              {this.data == null ? (
                <Loading />
              ) : (
                <div>
                  <table
                    id="zero-config"
                    className="table table-hover"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="Gold"
                            stroke="Gold"
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-star"
                          >
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                          </svg>
                        </th>
                        <th scope="col">م</th>
                        <th scope="col">تاريخ</th>
                        <th scope="col">الاسم</th>
                        <th scope="col">البريد</th>
                        {/* <th scope="col">الهاتف</th> */}
                        <th scope="col">الصورة</th>
                        {/* <th scope="col">الحالة</th> */}
                        <th scope="col">تفاصيل</th>
                      </tr>
                    </thead>

                    {this.paginatedList().map((e, index) => (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="Gold"
                              stroke="Gold"
                              strokeWidth="0"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-star"
                            >
                              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                            </svg>
                          </td>
                          <td>{this.state.filteredData.indexOf(e) + 1}</td>
                          <td>
                            {e?.createdAt.split("T")[1]}
                            <br />
                            {e?.createdAt.split("T")[0]}
                          </td>

                          <td>{e?.fullName}</td>
                          <td>{e?.email}</td>
                          {/* <td>{e?.userName}</td> */}
                          <td>
                            <div className="d-flex">
                              <div className="usr-img-frame mr-2 rounded-circle">
                                {
                                  <img
                                    className="img-fluid rounded-circle"
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                    }}
                                    src={
                                      window.baseurl +
                                      "uploads/" +
                                      e.profileImage
                                    }
                                    title={e.fullName}
                                    onError={(e) => (
                                      (e.target.onerror = null),
                                      ((e.target.src = "../img/no-image.png"),
                                      (e.target.title = "Image not found"))
                                    )}
                                    alt="img"
                                  />
                                }
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <div className="row cursor-pointer">
                              <h6
                                role={"button"}
                                className={
                                  e?.statuse == 0 || e?.statuse == null
                                    ? "visible badge badge-danger"
                                    : "visible pend badge badge-primary"
                                }
                              >
                                {e?.statuse == 0 || e?.statuse == null
                                  ? "غير مفعل "
                                  : " مفعل "}
                              </h6>
                            </div>
                          </td> */}
                          <td>
                            <Link
                              className="btn btn-outline-primary rounded-circle mx-2"
                              title="الصلاحيات"
                              to={"/admin/roles"}
                              state={{
                                row: e,
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-tool"
                                title="الصلاحيات"
                              >
                                <path d="M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z"></path>
                              </svg>
                            </Link>

                            <button
                              className="btn btn-outline-danger rounded-circle mx-2"
                              data-toggle="modal"
                              data-target="#DeleteModal"
                              title="حذف"
                              state={{
                                e,
                              }}
                              onClick={() => this.onSubmit(e.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    ))}

                    <tfoot>
                      <tr>
                        <th>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="Gold"
                            stroke="Gold"
                            strokeWidth="0"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-star"
                          >
                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                          </svg>
                        </th>
                        <th scope="col">م</th>
                        <th scope="col">تاريخ</th>
                        <th scope="col">الاسم</th>
                        <th scope="col">البريد</th>
                        {/* <th scope="col">الهاتف</th> */}
                        <th scope="col">الصورة</th>
                        {/* <th scope="col">الحالة</th> */}
                        <th scope="col">تفاصيل</th>
                      </tr>
                    </tfoot>
                  </table>
                  <div dir="ltr" className="mx-auto mt-[40px]">
                    <Pagination
                      count={Math.ceil(
                        this.state.filteredData.length / this.pageCount
                      )}
                      onChange={this.onPaginationChange}
                      variant="outlined"
                      color="primary"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
