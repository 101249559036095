// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { Link } from "react-router-dom";
import Loading from "../Components/preLoading";
import { Pagination } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Fields extends React.Component {
  componentDidMount() {
    this.getData();
  }

  results = (e) => {
    if (e === "OK") {
      toast.success(
        "Thank you for your message. We will get back to you as soon as possible.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (e === "inputNull") {
      toast.error("أدخل البريد الإلكتروني وكلمة المرور", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "userName") {
      toast.error("اكتب اسم المستخدم", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "password") {
      toast.error("اكتب كلمة المرور", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "WrongCredentials") {
      toast.error("الرجاء إدخال البريد الإلكتروني الصحيح وكلمة المرور", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  DeleteAdmin = async (e) => {
    // console.log(e);
    const formData = new FormData();
    formData.append("UserId", e);
    try {
      const response = await fetch(window.baseurl + "field/" + e, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      this.data = json;
      this.getData();
      if (json.response === "OK") {
        this.results("OK");
      } else {
        this.results("error");
      }

      // console.log(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };
  onSubmit = (e) => {
    confirmAlert({
      title: "تأكيد للحذف",
      message: "سيتم حذف المستخدم بشكل دائم",
      buttons: [
        {
          label: "نعم ، احذف",
          onClick: () => this.DeleteAdmin(e),
        },
        {
          label: "رقم",
        },
      ],
    });
  };

  pageCount = 10;
  search = "";

  constructor(props) {
    super(props);
    this.state = {
      city: "الكل",
      page: 1,
      filteredData: null,
    };
    this.handleChange = this.handleChange.bind(this);

    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  onPaginationChange(event, value) {
    this.setState({ page: value });
  }

  paginatedList() {
    return this.state.filteredData.slice(
      (this.state.page - 1) * this.pageCount,
      this.state.page * this.pageCount
    );
  }

  filterData(city) {
    this.state.filteredData =
      city === "الكل"
        ? this.data
        : this.data.filter((e) => e.user?.city === city);
    return this.filteredData;
  }
  getData = async () => {
    try {
      const response = await fetch(window.baseurl + "field/get-fields", {
        method: "Post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      // console.log(json);
      this.data = json;
      this.searchData(this.search);
     // console.log(json);
      return json;

    } catch (error) {
      console.error(error);
    }
  };
  data = null;
  searchData(search) {
    this.state.filteredData =
      search === ""
        ? this.data
        : this.data.filter((e) => e?.name.includes(search));
    this.setState({});
  }

  handleChange(e) {
   // console.log("Fruit Selected!!");
    this.filterData(e.target.value);
    this.setState({ city: e.target.value });
  }

  render() {
    return (
      <>
        <ToastContainer />
        <div className="py-4 px-5">
          <h1>قائمة السائقين</h1>
        </div>

        <div className="layout-px-spacing">
          <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div className="widget-content widget-content-area br-6">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <label asp-for="Name"> بحث</label>
                    <input
                      type="text"
                      onChange={(e) => this.searchData(e.target.value)}
                      className="form-control"
                      id="name"
                      placeholder=" الاسم ، رقم الهاتف ، الايميل"
                    />
                  </div>
                  <div className="col-md-4">
                    <Link
                      to={"/admin/field/add"}
                      className="btn btn-warning rounded mt-4"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-plus-circle"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                      </svg>
                      &nbsp;&nbsp; إضافة&nbsp;&nbsp;
                    </Link>
                  </div>
                </div>
                <div className="table-responsive mb-4 mt-4">
                  {this.data === null ? (
                    <Loading />
                  ) : (
                    <div>
                      <table
                        id="zero-config"
                        className="table table-hover filesTable"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="Gold"
                                stroke="Gold"
                                strokeWidth="0"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-star"
                              >
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                              </svg>
                            </th>
                            <th scope="col">م</th>
                            <th scope="col">اسم</th>
                            <th scope="col">الصورة</th>
                            <th scope="col">مفعل</th>
                            <th scope="col">إجراء</th>
                          </tr>
                        </thead>
                        {this.paginatedList().map((e, index) => (
                          <tbody key={index}>
                            <tr key={index}>
                              <td>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="Gold"
                                  stroke="Gold"
                                  strokeWidth="0"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-star"
                                >
                                  <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                </svg>
                              </td>
                              <td>{this.state.filteredData.indexOf(e) + 1}</td>
                              <td>{e.name===null?e.name : e.name.split("大")[0]}</td>
                              <td>
                                <div className="d-flex">
                                  <div className="usr-img-frame mr-2 rounded-circle">
                                    {
                                      <img
                                        className="img-fluid rounded-circle"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={
                                          window.baseurl + "uploads/" + e?.image
                                        }
                                        title={e.name===null?e.name : e.name.split("大")[0]}
                                        // onError={(e) => (
                                        //   (e.target.onError = null),
                                        //   ((e.target.src = "../img/no-image.png"),
                                        //   (e.target.title = "Image not found"))
                                        // )}
                                        alt="img"
                                      />
                                    }
                                  </div>
                                </div>
                              </td>
                              <td>
                                <h6
                                  className={
                                    e?.status == 0|| e?.status == null|| e?.status == undefined
                                      ? "pend  d-none"
                                      : "badge badge-success"
                                  }
                                >
                                  مفعل
                                </h6>
                                <h6
                                  className={
                                    e?.status == 1
                                      ? "pend   d-none"
                                      : "badge badge-danger"
                                  }
                                >
                                  غير مفعل
                                </h6>
                              </td>

                              <td>
                                <Link
                                  className="btn btn-outline-primary rounded-circle mx-2"
                                  to={"/admin/Field/Edit"}
                                  state={{
                                    row: e,
                                  }}
                                  title="تعديل"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-edit"
                                  >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg>
                                </Link>
                                <button
                                  className="btn btn-outline-danger rounded-circle mx-2"
                                  data-toggle="modal"
                                  data-target="#DeleteModal"
                                  title="حذف"
                                  state={{
                                    e,
                                  }}
                                  onClick={() => this.onSubmit(e.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-trash"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                        <tfoot>
                          <tr>
                            <th scope="col">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="Gold"
                                stroke="Gold"
                                strokeWidth="0"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-star"
                              >
                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                              </svg>
                            </th>
                            <th scope="col">م</th>
                            <th scope="col">اسم</th>
                            <th scope="col">الصورة</th>
                            <th scope="col">مفعل</th>
                            <th scope="col">إجراء</th>
                          </tr>
                        </tfoot>
                      </table>
                      <div dir="ltr" className="mx-auto mt-[40px]">
                        <Pagination
                          count={Math.ceil(
                            this.state.filteredData.length / this.pageCount
                          )}
                          onChange={this.onPaginationChange}
                          variant="outlined"
                          color="primary"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
