import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../Components/preLoading";
import { Pagination } from "@mui/material";

export default class Orders extends React.Component {
  pageCount = 10;
  onPaginationChange(event, value) {
    this.setState({ page: value });
  }

  paginatedList() {
    return this.state.filteredData.slice(
      (this.state.page - 1) * this.pageCount,
      this.state.page * this.pageCount
    );
  }
  componentDidMount() {
    this.getData();
  }

  constructor(props) {
    super(props);
    this.state = {
      status: "الكل",
      page: 1,

      filteredData: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  handleChange(e) {
    //console.log("Fruit Selected!!");
    this.filterData(e.target.value);
    this.setState({ status: e.target.value });
  }

  data = null;
  filterData(status) {
    this.state.filteredData =
      status == "الكل"
        ? this.data
        : this.data.filter((e) => this.orderStatus[e.order?.status] == status);
    return this.filteredData;
  }

  searchData(search) {
    this.state.filteredData =
      search == ""
        ? this.data
        : this.data.filter(
            (e) =>
              e.user?.email.includes(search) ||
              e.user?.userName.includes(search) ||
              e.user?.fullName.includes(search)
          );
    this.setState({});
  }

  orderStatus = [
    "لم يتم دفع سعر الطلب",
    "في انتظار موافقة المطبخ",
    "رفض الطلب",
    "موافقة علي الطلب",
    "انهاء الطلب من المطبخ",
    "موافقة مندوب علي  الطلب",
    "تم استلام الطلب من المطبخ",
    "تم تسليم الطلب للعميل",
    "تم انهاء الطلب بنجاح",
  ];
  getData = async () => {
    try {
      const response = await fetch(window.baseurl + "admin/get-orders", {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      this.data = json;
      this.filterData("الكل");
      this.setState({});
      console.log(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <>
        <div className="py-4 px-5">
          <h1>الطلبات</h1>
        </div>
        <div className="layout-px-spacing">
          <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div className="widget-content widget-content-area br-6">
                <div className="row">
                  <div className="col-md-8">
                    <label asp-for="Name"> بحث</label>
                    <input
                      type="text"
                      onChange={(e) => this.searchData(e.target.value)}
                      className="form-control"
                      id="name"
                      placeholder=" الاسم ، رقم الهاتف ، الايميل"
                    />
                  </div>
                  <div className="col-md-4">
                    <label asp-for="Name">حسب حالة الطلب </label>
                    <select
                      asp-for="City"
                      name="room"
                      value={this.state.status}
                      onChange={this.handleChange}
                      className="form-control select2"
                      style={{ width: "100%" }}
                    >
                      <option value="الكل">الكل</option>
                      {this.orderStatus.map((row, index) => (
                        <option key={index} value={row}>
                          {row}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="table-responsive mb-4 mt-4">
                  {this.state.filteredData == null ? (
                    <Loading />
                  ) : (
                    <div>
                      <table
                        id="zero-config"
                        className="table table-hover filesTable"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">م</th>
                            <th scope="col">التاريخ</th>
                            <th scope="col">البريد</th>
                            <th scope="col">الهاتف</th>
                            <th scope="col">الحالة</th>
                            <th scope="col">تفاصيل</th>
                          </tr>
                        </thead>

                        {this.paginatedList().map((e, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{this.state.filteredData.indexOf(e) + 1}</td>
                              {/* <td>{this.order?.id}</td> */}
                              <td>
                                {e?.user?.createdAt.split("T")[1]}
                                <br />
                                {e?.user?.createdAt.split("T")[0]}
                              </td>

                              <td>{e?.user?.email}</td>

                              <td>{e?.user?.userName.replaceAll("+", "")}</td>
                              <td>
                                <span
                                  className={
                                    e.order.status == 0
                                      ? "badge badge-warning"
                                      : e.order.status == 1
                                      ? "badge badge-info"
                                      : e.order.status == 2
                                      ? "badge badge-danger"
                                      : e.order.status == 3
                                      ? "badge badge-info"
                                      : e.order.status == 4
                                      ? "badge badge-primary"
                                      : e.order.status == 5
                                      ? "badge badge-info"
                                      : e.order.status == 6
                                      ? "badge badge-success"
                                      : e.order.status == 7
                                      ? "badge badge-primary"
                                      : e.order.status == 8
                                      ? "badge badge-success"
                                      : "badge badge-danger"
                                  }
                                >
                                  {this.orderStatus[e.order.status]}
                                </span>
                              </td>

                              <td>
                                <Link
                                  to={"/admin/order/detail"}
                                  state={{
                                    row: e,
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="blue"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-eye"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                        <tfoot>
                          <tr>
                            <th scope="col">م</th>
                            <th scope="col">التاريخ</th>
                            <th scope="col">البريد</th>
                            <th scope="col">الهاتف</th>
                            <th scope="col">الحالة</th>
                            <th scope="col">تفاصيل</th>
                          </tr>
                        </tfoot>
                      </table>
                      <div dir="ltr" className="mx-auto mt-[40px]">
                        <Pagination
                          count={Math.ceil(
                            this.state.filteredData.length / this.pageCount
                          )}
                          onChange={this.onPaginationChange}
                          variant="outlined"
                          color="primary"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
