import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export default function TransactionDetails() {
  const [transaction, setTransaction] = useState(null);

  const location = useLocation();
  const row = location.state.row;
 // console.log(row);
  const imgStyle = { height: "100px", borderRadius: "100px", margin: "auto" };

  const getTransaction = async () => {
    try {
      var form = new FormData();
      form.append("orderId", row?.order?.id);
      const response = await fetch(
        window.baseurl + "admin/transaction/detail",
        {
          method: "post",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: form,
        }
      );
      const json = await response.json();
      setTransaction(json);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getTransaction();
  }, []);

  return (
    <>
      <div className="py-4 px-5">
        <h1>أوصاف الطلب</h1>
      </div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="statbox ">
          <div className="widget-content widget-content-area">
            <div className="row">
              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">اسم المطبخ</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={row?.order?.market_name?.split("大")[0]}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">اسم العميل </h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={transaction?.user_name}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">تاريخ العملية</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={
                    row?.order?.date.split("T")[1] +
                    " " +
                    row?.order?.date.split("T")[0]
                  }
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">قيمة الوجبه</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={transaction?.cart_price}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">قيمة التوصيل</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={transaction?.delivery_price}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">قيمة الضريبه المضافة</h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={transaction?.tax_price?.toFixed(2)}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">قيمة التطبيق </h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={transaction?.application_price}
                  disabled
                />
              </div>

              <div className="col-xl-3 col-md-4 col-sm-12 col-12 py-2">
                <h5 className="item-title">القيمه الاجماليه </h5>
                &nbsp;
                <input
                  className="form-control disabled-form-control"
                  value={transaction?.total_price}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
