import React from "react";
import * as constants from "../Components/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class RegisterDriver extends React.Component {
  componentDidMount() {}

  profileImage = null;
  gender = null;
  city = null;
  carImage = null;


  constructor(props) {
    super(props);
    this.state = {
      file: "../img/account.jpeg",
      carImage: null,
      error: null,
    };
    this.uploadSingleFile = this.uploadSingleFile.bind(this);
    this.uploadCarImage = this.uploadCarImage.bind(this);
    this.upload = this.upload.bind(this);
  }
  uploadSingleFile(e) {
    //console.log("good");
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),
    });
    this.uploadImage(e.target.files[0],"profileImage");
  }

  uploadCarImage(e){
    this.setState({
      carImage: URL.createObjectURL(e.target.files[0]),
    });
    this.uploadImage(e.target.files[0],"carImage");
  }


  upload(e) {
    e.preventDefault();
    //console.log(this.state.file);
  }

  uploadImage = async (file,param) => {
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("modle", "user");
    try {
      const response = await fetch(window.baseurl + "" + "admin/image/upload", {
        method: "POST",
        body: formdata,
      });
      const json = await response.json();
      //console.log(json.imageUrl);
      if (param == "profileImage") {
        this.profileImage = json.imageUrl;
      } else if (param == "carImage") {
        this.carImage = json.imageUrl;
      }
    } catch (error) {
      console.error(error);
    }
  };

  postForm = async (event) => {
    event.preventDefault();
    this.setState({ error: null });
    const formdata = new FormData(event.target);
    const un = "+966" + event.target.elements.userName.value
    // console.log("un");
    // console.log(un);
    formdata.delete("userName");
    formdata.append("userName", un);
    formdata.append("knownName", "driver");
    formdata.append("country", this.country);
    formdata.append("city", this.city);
    formdata.append("gender", this.gender);
    formdata.append("address", "jubail");
    formdata.append("lat", 39);
    formdata.append("lng", 26);
    formdata.append("carImage", this.carImage);
    formdata.append("profileImage", this.profileImage ?? "a.jpeg");
    formdata.append("role", "driver");

    if(un.length!=13||un.startsWith("+9665")==false){
      // this.setState({ error: " رقم الهاتف الذي أدخلته غير صحيح" });
      console.log("error");
      return
    }


    //console.log("post");
    try {
      const response = await fetch(window.baseurl + "" + "auth/driver-signup", {
        method: "POST",
        body: formdata,
      });

      const json = await response.json();
      //console.log(json);
      if (json.error) {
        // this.setState({ error: json.error });
        console.log(json.error);
        return;
      }

      // window.status = 0;
      // window.location = "/success";
    } catch (error) {}
  };

  render() {
    return (
      <>
        <div className="form-container row">
          <div className="form-image col-md-6 col-12">
            <div className="l-image text-center">
              <h1>
                <span>تسجيل</span> مستخدم جديد
              </h1>
            </div>
          </div>
          <div className="form-form  col-md-6 col-12">
            <div className="form-form-wrap">
              <div className="form-container">
                <div className="form-content">
                  <div className="text-left">
                  <form onSubmit={this.postForm}>
                      <div className="form">
                        <h4>البيانات الشخصية</h4>
                        <div className="border-bottom py-2">
                          {/* user image */}
                          <div className="row">
                            <div className="col-6">
                              <label className="textOrange"> صورة الحساب</label>
                              <div
                                id="user-Image-field"
                                className="field-wrapper input"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-image"
                                >
                                  <rect
                                    x="3"
                                    y="3"
                                    width="18"
                                    height="18"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                  <polyline points="21 15 16 10 5 21"></polyline>
                                </svg>
                                <input
                                  // required
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity(
                                      "اختر صورة الحساب"
                                    )
                                  }
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                  type="file"
                                  onChange={this.uploadSingleFile}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={this.state.file}
                              />
                            </div>
                          </div>

                          {/* full name */}
                          <label className="textOrange">اسم المستخدم</label>
                          <div
                            id="fullName-field"
                            className="field-wrapper input"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-user"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <input
                              id="fullName"
                              name="fullName"
                              type="text"
                              className="form-control"
                              placeholder="اكتب اسمك هنا"
                              onInvalid={(e) =>
                                e.target.setCustomValidity("اكتب الاسم")
                              }
                              onInput={(e) => e.target.setCustomValidity("")}
                            />
                          </div>

                          {/* uername */}
                          <label className="textOrange">
                            {"رقم الجوال" + "  " + "966+"}
                          </label>
                          <div
                            id="username-field"
                            className="field-wrapper input"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-phone"
                            >
                              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                            </svg>
                            <input
                              id="username"
                              className="form-control"
                              required
                              maxLength="9"
                              placeholder="5xxxxxxxx"
                              name="userName"
                              onInvalid={(e) =>
                                e.target.setCustomValidity("اكتب رقم جوالك")
                              }
                              onInput={(e) => {
                                e.target.setCustomValidity("");
        
                                e.target.value = Math.max(0, parseInt(e.target.value))
                                  .toString()
                                  .slice(0, 9);
                              }}
                              type={"number"}
                            />
                          </div>

                          {/* email */}
                          <label className="textOrange">
                            البريد الإلكتروني
                          </label>
                          <div id="email-field" className="field-wrapper input">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-at-sign"
                            >
                              <circle cx="12" cy="12" r="4"></circle>
                              <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                            </svg>
                            <input
                              id="email"
                              className="form-control"
                              placeholder="اكتب بريدك الالكتروني "
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "اكتب بريدك الالكتروني"
                                )
                              }
                              onInput={(e) => e.target.setCustomValidity("")}
                              name="email"
                              type={"text"}
                            />
                          </div>

                          {/* Age and Gender */}
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <label className="textOrange">الجنس</label>
                              <div
                                id="email-field"
                                className="field-wrapper input"
                              >
                                <select
                                  className="form-control"
                                  required
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity("اختر الجنس")
                                  }
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                  onChange={(e) =>
                                    (this.gender = e.target.value)
                                  }
                                >
                                  <option value="">اختر</option>
                                  <option value="ذكر">ذكر</option>
                                  <option value="انثى">انثى</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <label className="textOrange">العمر</label>
                              <div
                                id="email-field"
                                className="field-wrapper input"
                              >
                                <input
                                  className="form-control"
                                  required
                                  placeholder="العمر "
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity("العمر")
                                  }
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                  name="age"
                                  type={"number"}
                                />
                              </div>
                            </div>
                          </div>

                          {/* City and Country */}
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <label className="textOrange">الجنسية</label>
                              <div
                                id="email-field"
                                className="field-wrapper input"
                              >
                                <select
                                  required
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity("اختر الجنسية")
                                  }
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                  onChange={(e) =>
                                    (this.country = e.target.value)
                                  }
                                  className="form-control"
                                >
                                  <option value="">اختر</option>
                                  {constants.countries.map((item) => (
                                    <option value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              <label className="textOrange">المدينة</label>
                              <div
                                id="email-field"
                                className="field-wrapper input"
                              >
                                <select
                                  className="form-control"
                                  required
                                  onInvalid={(e) =>
                                    e.target.setCustomValidity("اختر المدينة")
                                  }
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                  onChange={(e) => (this.city = e.target.value)}
                                >
                                  <option value="">اختر</option>
                                  {constants.cities.map((item) => (
                                    <option value={item.name}>{item}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <h4>معلومات اخرى</h4>
                        <div className="row">
                          <div className="col-6">
                            {" "}
                            <label className="textOrange">صورة السيارة</label>
                            <div
                              id="car-Image-field"
                              className="field-wrapper input"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-image"
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  width="18"
                                  height="18"
                                  rx="2"
                                  ry="2"
                                ></rect>
                                <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                <polyline points="21 15 16 10 5 21"></polyline>
                              </svg>
                              <input
                                required
                                onInvalid={(e) =>
                                  e.target.setCustomValidity(
                                    "اختر صورة للسيارة "
                                  )
                                }
                                onInput={(e) => e.target.setCustomValidity("")}
                                type={"file"}
                                onChange={(e) => this.uploadCarImage(e)}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            {this.state.carImage && (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                src={this.state.carImage}
                              />
                            )}
                          </div>
                        </div>

                        {/* Vehicle Type */}
                        <label className="textOrange">نوع السيارة</label>
                        <div
                          id="username-field"
                          className="field-wrapper input"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-truck"
                          >
                            <rect x="1" y="3" width="15" height="13"></rect>
                            <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                            <circle cx="5.5" cy="18.5" r="2.5"></circle>
                            <circle cx="18.5" cy="18.5" r="2.5"></circle>
                          </svg>
                          <input
                            id="carType"
                            className="form-control"
                            required
                            placeholder="نوع السيارة "
                            onInvalid={(e) =>
                              e.target.setCustomValidity("نوع السيارة")
                            }
                            onInput={(e) => e.target.setCustomValidity("")}
                            name="carType"
                            type={"text"}
                          />
                        </div>
                      </div>
                      <input
                        type="submit"
                        className="btn btn-primary col-6"
                        form="form"
                        value="إرسال"
                      />
                       <input value={"تسجيل"} type="submit" />
                    </form>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
