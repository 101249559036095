import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/preLoading";

import ChartView from "../Components/charts/ApexChart";
import PieChartView from "../Components/charts/PieChart";

import LineChartOrders from "../Components/charts/LineChartOrders";
import OrderStatusChart from "../Components/charts/OrderStatusChart";

import LineChart from "../Components/charts/LineChart";
import DriversRateChart from "../Components/charts/DriversRateChart";

export default function Home() {
  var [data, setData] = useState(null);

  const orderStatus = [
    "في انتظار التأكيد",
    "جاري تجهيز الطلب",
    "جاري التوصيل",
    "تم تسليم الطلب",
    "طلب ملغي",
  ];
  const navigate = useNavigate();
  const getData = async () => {
    try {
      const response = await fetch(window.baseurl + "admin/home", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      console.log(json);
      setData(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };
  if (data === null) getData();
  return data === null ? (
    <Loading />
  ) : (
    <>
      <div className="py-4">
        <h1>الاداء والتقييم</h1>
      </div>

      <div className="User-Cards col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <h3 className="textOrange">احصائيات المستخدمين</h3>
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <span className="h1 font-weight-bold mb-0">
                      {data.users}
                    </span>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      عدد العملاء
                    </h5>
                  </div>
                  <div className="col-4">
                    <svg
                      viewBox="0 0 80 80"
                      width="80"
                      height="80"
                      className="rounded-circle shadow"
                      fill="#f89d54"
                    >
                      <circle cx="40" cy="40" r="38" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="80px"
                        height="80px"
                      >
                        <path
                          d="M16 8C13.25 8 11 10.25 11 13C11 14.515625 11.707031 15.863281 12.78125 16.78125C11.714844 17.339844 10.78125 18.183594 10.125 19.1875C9.769531 18.671875 9.300781 18.195313 8.78125 17.84375C9.523438 17.117188 10 16.113281 10 15C10 12.800781 8.199219 11 6 11C3.800781 11 2 12.800781 2 15C2 16.113281 2.476563 17.117188 3.21875 17.84375C1.886719 18.746094 1 20.285156 1 22L3 22C3 20.34375 4.34375 19 6 19C7.65625 19 9 20.34375 9 22L9 23L11 
                          23C11 20.246094 13.246094 18 16 18C18.753906 18 21 20.246094 21 23L23 23L23 22C23 20.34375 24.34375 19 26 19C27.65625 19 29 20.34375 29 22L31 22C31 20.285156 30.113281 18.746094 28.78125 17.84375C29.523438 17.117188 30 16.113281 30 15C30 12.800781 28.199219 11 26 11C23.800781 11 22 12.800781 22 15C22 16.113281 22.476563 17.117188 23.21875 17.84375C22.699219 18.195313 22.230469 18.671875 21.875 19.1875C21.21875 18.183594 20.285156 17.339844 
                          19.21875 16.78125C20.292969 15.863281 21 14.515625 21 13C21 10.25 18.75 8 16 8 Z M 16 10C17.667969 10 19 11.332031 19 13C19 14.667969 17.667969 16 16 16C14.332031 16 13 14.667969 13 13C13 11.332031 14.332031 10 16 10 Z M 6 13C7.117188 13 8 13.882813 8 15C8 16.117188 7.117188 17 6 17C4.882813 17 4 16.117188 4 15C4 13.882813 4.882813 13 6 13 Z M 26 13C27.117188 13 28 13.882813 28 15C28 16.117188 27.117188 17 26 17C24.882813 17 24 16.117188 24 15C24 13.882813 24.882813 13 26 13Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <span className="h1 font-weight-bold mb-0">
                      {data.providers}
                    </span>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      عدد الطباخين
                    </h5>
                  </div>
                  <div className="col-4">
                    <svg
                      viewBox="0 0 80 80"
                      width="80"
                      height="80"
                      className="rounded-circle shadow"
                      fill="#f89d54"
                    >
                      <circle cx="40" cy="40" r="38" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="80px"
                        height="80px"
                      >
                        <path
                          d="M16 3C13.753906 3 11.835938 4.289063 10.8125 6.125C10.542969 6.082031 10.296875 6 10 6C6.699219 6 4 8.699219 4 12C4 14.601563 5.671875 16.832031 8 17.65625L8 29L24 29L24 17.65625C26.328125 16.832031 28 14.601563 28 12C28 8.699219 25.300781 6 22 6C21.703125 6 21.457031 6.082031 21.1875 6.125C20.164063 4.289063 18.246094 3 16 3 Z M 16 5C17.707031 5 19.140625 6.0625 19.71875 7.5625L20.0625 8.40625L20.9375 8.15625C21.316406 8.050781 21.652344 8 22 8C24.21875 8 26 9.78125 26 12C26 14.21875 24.21875 16 22 16L22 23L10 23L10 16C7.78125 16 6 14.21875 6 12C6 9.78125 7.78125 8 10 8C10.347656 8 10.683594 8.050781 11.0625 8.15625L11.9375 8.40625L12.28125 7.5625C12.859375 6.0625 14.292969 5 16 5 Z M 13 17L13 21L15 21L15 17 Z M 17 17L17 21L19 21L19 17 Z M 10 25L22 25L22 27L10 27Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <span className="h1 font-weight-bold mb-0">
                      {data.drivers}
                    </span>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      عدد المناديب
                    </h5>
                  </div>
                  <div className="col-4">
                    <svg
                      viewBox="0 0 80 80"
                      width="80"
                      height="80"
                      className="rounded-circle shadow"
                      fill="#f89d54"
                    >
                      <circle cx="40" cy="40" r="38" />
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        height="80"
                        viewBox="0 0 70.000000 70.000000"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(0.000000,70.000000) scale(0.013672,-0.013672)"
                          fill="#000000"
                          stroke="none"
                        >
                          <path
                            d="M2245 5114 c-497 -52 -831 -173 -1011 -366 -239 -259 -135 -644 229 -843 l67 -37 0 -188 0 -187 -46 -13 c-70 -20 -161 -85 -209 -152 -62 -84 -80 -142 -80 -264 0 -95 2 -104 37 -175 56 -112 156 -201 259 -229 l36 -10 6 -102 c9 -175 46 -242 214 -390 62 -55 113 -104 113 -108 0 -4 -21 -20 -47 -35 -27 -16 -79 -50 -116 -78 -66 -48 -73 -51 -303 -109 -130 -32 -259 -70 -287 -84 -117 -60 -220 -197 -247 -330 -7 -31 -25 -75 -41 -98 -45 -65 -59 -139 -59 -316 l0 -155 -36 -20 c-51 -27 -102 -91 -119 -149 -21 -70 -21 -340 0 -421 29 -112 104 -196 213 -238 39 -16 178 -17 1742 -17 1560 0 1703 1 1742 17 107 41  184 127 213 238 21 81 21 351 0 421 -17 58 -68 122 -119 149 l-36 20 0 155 c0 177 -14 251 -59 316 -16 23 -34 67 -41 98 -27 133 -130 270 -247 330 -28 14 -157 52 -287 84 -230 58 -237 61 -303 109 -37 28 -89 62 -115 78 -27 15 -48
                            30 -48 35 0 4 49 51 110 104 170 149 208 219 217 393 l6 102 45 15 c140 48 247 168 282 315 16 66 8 180 -16 241 -47 120 -150 222 -260 257 l-54 17 0 187 0 187 68 37 c202 111 328 278 352 467 45 361 -293 616 -954 720 -108 17 -187 21 -451 23 -176 2 -338 1 -360 -1z m636 -184 c415 -42 719 -148 866 -302 65 -69 87 -126 81 -215 -7 -125 -77 -231 -210 -319 -58 -39 -73 -45 -87 -35 -58 42 -36 41 -971 41 -935 0 -913 1 -971 -41 -14 -10 -29 -4 -87 35 -137 91 -204 194 -210 327 -3 66 -1 82 21 125 98 191 439 335 912 383 118 12 537 13 656 1z m529 -1135 l0 -125 -850 0 -850 0 0 125 0 125 850 0 850 0 0 -125z m-124 -432 c-67 -134 -98 -173 -155 -192 -24 -8 -190 -11 -545 -11 -625 0 -559 -17 -693 180 -51 74 -92 138 -92 143 -1 4 348 7 774 7 l775 0 -64 -127z m-1428 -286 c15 -15 56 -42 92 -60 l65 -32 570 0 c653 0 632 -2 729 86 40 37 68 75 
                            106 149 49 96 52 100 84 100 84 0 168 -52 212 -132 54 -99 35 -217 -48 -294 -46 -44 -87 -63 -158 -73 -92 -12 -94 -16 -100 -189 -7 -201 -10 -206 -198 -371 l-146 -129 -54 19 c-142 49 -248 64 -452 64 -204 0 -310 -15 -452 -64 l-54 -19 -146 129 c-188 165 -191 170 -198 371 -6 173 -8 177 -100 189 -143 20 -234 117 -235 249 0 53 6 75 29 118 44 79 128 132 210 132 17 0 37 4 45 9 11 7 33 -17 94 -107 43 -64 90 -130 105 -145z m908 -1052 c105 -19 267 -71 356 -116 359 -179 625 -533 693 -923 23 -135 28 -126 -64 -126 l-79 0 -12 82 c-58 398 -363 763 -735 877 -100 31 -167 18 -227 -46 -56 -58 -58 -74 -58 -423 l0 -322 28 -26 c16 -15 44 -40 62 -57 39 -35 80 -117 80 -162 0 -65 12 -74 275 -227 139 -80 269 -153 289 -161 l36 -15 0 -84 c0 -47 3 -91 6 -100 5 -13 -1  -16 -26 -16 -25 0 -41 9 -69 40 -48 53 -545 340 -589 340 -16 0 -49 -9 -73
                            -20 -27 -12 -66 -20 -99 -20 -33 0 -72 8 -99 20 -24 11 -57 20 -73 20 -44 0  -541 -287 -589 -340 -28 -31 -44 -40 -69 -40 -25 0 -31 3 -26 16 3 9 6 53 6  100 l0 84 38 15 c20 9 150 82 288 162 262 151 274 161 274 226 0 45 41 127 80 162 18 17 46 42 62 57 l28 26 0 325 0 325 -28 53 c-22 43 -38 59 -81 81 -31  16 -69 28 -90 28 -50 -1 -196 -58 -289 -115 -278 -167 -464 -434 -525 -752 l-22 -113 -77 0 c-91 0 -86 -9 -63 126 75 426 367 787 774 959 73 31 246 79  316 88 80 11 290 6 371 -8z m-466 -455 c0 -67 0 -68 -32 -81 -18 -7 -64 -44  -102 -81 l-69 -69 -29 28 c-27 26 -88 113 -88 126 0 12 147 102 218 133 94 41 102 37 102 -56z m683 29 c23 -12 68 -39 99 -61 43 -29 56 -42 51 -56 -10 -27  -58 -95 -86 -121 l-26 -23 -55 59 c-30 33 -76 71 -101 84 l-45 24 0 65 c0 37  5 71 11 77 12 12 67 -5 152 -48z m-1599 29 c-4 -7 -24 -38 -44 -68 
                            -21 -30  -49 -79 -64 -107 -14 -29 -27 -53 -29 -53 -1 0 -24 11 -51 25 -27 14 -67 25  -88 25 -21 0 -38 4 -38 10 0 16 69 88 108 112 20 12 73 32 117 44 97 27 98 27  89 12z m2446 -13 c36 -9 83 -27 106 -40 42 -25 114 -97 114 -115 0 -6 -17 -10-38 -10 -21 0 -61 -11 -88 -25 -27 -14 -50 -25 -51 -25 -2 0 -15 24 -29 53  -15 28 -43 77 -64 107 -55 83 -55 83 -34 77 11 -3 48 -13 84 -22z m-1881 -388 c59 -62 94 -80 157 -80 65 0 105 23 152 88 l37 50 3 -84 3 -83 -39 -37 c-56 -52 -91 -110 -116 -190 l-21 -70 -205 -120 -205 -119 -6 47 c-7 51 -30 104 -62 138 -56 62 38 384 158 546 l36 48 31 -43 c17 -24 51 -65 77 -91z m1435 -26 c54 -104 94 -238 103 -338 5 -65 4 -76 -14 -96 -32 -34 -55 -87 -62 -138 l-6 -46 -203 117 c-111 65 -205 120 -208 123 -3 3 -10 24 -15 48 -16 68 -58 144 -111 198 l-49 50 3 83 3 83 38 -51 c20 -29 54 -60 74 -69 51 
                            -24 124 -22 167 4 39 25 127 117 153 162 l18 31 32 -37 c18 -21 52 -77 77 -124z m-2237 36 l24 -23 -26 -105 c-26 -108 -47 -313 -38 -362 3 -15 17 -36 31 -47 22 -18 40 -20 179 -20 l155 0 29 -29 29 -29 0 -197 c0 -195 0 -196 -25 -220 l-24 -25 -292 0 c-329 0 -340 2 -387 73 -27 40 -27 43 -27 208 0 190 5 204 79 216 22 3 50 17 63 29 22 23 23 28 23 226 0 224 7 268 51 309 47 44 110 42 156 -4z m2981 5 c45 -42 52 -85 52 -310 0 -198 1 -203 23 -226 13 -12 41 -26 63 -29 74 -12 79 -26 79 -216 0 -165 0 -168 -27 -208 -47 -71 -58 -73 -387 -73 l-292 0 -24 25 c-25 24 -25 25 -25 220 l0 197 29 29 29 29 156 0 c149 0 156 1 183  24 27 24 28 27 26 118 -3 106 -16 198 -45 313 l-20 80 31 27 c44 39 107 39  149 0z m-1568 -902 c42 0 97 6 122 14 l46 13 150 -86 c83 -47 154 -89 158 -93  4 -5 -213 -8 -482 -8 l-489 0 164 94 164 93 45 -13 c25 -8 80 -14 122 -14z"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M2418 2800 c-14 -11 -28 -32 -31 -47 -7 -35 15 -88 41 -102 12 -6 71
                              -11 132 -11 61 0 120 5 132 11 26 14 48 67 41 102 -11 55 -42 67 -173 67 -102
                              0 -121 -3 -142 -20z"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M2329 2451 c-38 -39 -40 -88 -3 -125 l27 -26 207 0 207 0 27 26 c37
                              37 35 86 -3 125 l-29 29 -202 0 -202 0 -29 -29z"
                            fill="#FFFFFF"
                          />
                          <path
                            d="M2499 831 c-32 -33 -37 -67 -14 -110 16 -32 33 -41 75 -41 42 0 59 9
                              75 41 23 43 18 77 -14 110 -40 39 -82 39 -122 0z"
                            fill="#FFFFFF"
                          />
                        </g>
                      </svg>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <span className="h1 font-weight-bold mb-0">
                      {data.totalAdmins}
                    </span>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      عدد المشرفين
                    </h5>
                  </div>
                  <div className="col-4">
                    <svg
                      viewBox="0 0 80 80"
                      width="80"
                      height="80"
                      className="rounded-circle shadow"
                      fill="#f89d54"
                    >
                      <circle cx="40" cy="40" r="38" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#FFFFFF"
                        strokeWidth="1.4"
                        className="feather feather-users"
                      >
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                      </svg>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Order-Cards col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <h3 className="textOrange">أوامر احصائيات</h3>
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <span className="h1 font-weight-bold mb-0">
                      {data.totalFoods}
                    </span>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      مجموع الطعام
                    </h5>
                  </div>
                  <div className="col-4">
                    <svg
                      viewBox="0 0 80 80"
                      width="80"
                      height="80"
                      className="rounded-circle shadow"
                      fill="#f89d54"
                    >
                      <circle cx="40" cy="40" r="38" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="80"
                        height="80"
                      >
                        <path
                          d="M7.5 5C6.1364058 5 5 6.1364058 5 7.5L5 13.5C5 14.863594 6.1364058 16 7.5 16L13.5 16C14.863594 16 16 14.863594 16 13.5L16 7.5C16 6.1364058 14.863594 5 13.5 5L7.5 5 z M 8 8L13 8L13 13L8 13L8 8 z M 20.5 9 A 1.50015 1.50015 0 1 0 20.5 12L41.5 12 A 1.50015 1.50015 0 1 0 41.5 9L20.5 9 z M 7.5 18.5C6.1364058 18.5 5 19.636406 5 21L5 27C5 28.363594 6.1364058 29.5 7.5 29.5L13.5 29.5C14.863594 29.5 16 28.363594 16 27L16 21C16 19.636406 14.863594 18.5 13.5 18.5L7.5 18.5 z M 8 21.5L13 21.5L13 26.5L8 26.5L8 21.5 z M 20.5 22.5 A 1.50015 1.50015 0 1 0 20.5 25.5L41.5 25.5 A 1.50015 1.50015 0 1 0 41.5 22.5L20.5 22.5 z M 7.5 32C6.1364058 32 5 33.136406 5 34.5L5 40.5C5 41.863594 6.1364058 43 7.5 43L13.5 43C14.863594 43 16 41.863594 16 40.5L16 34.5C16 33.136406 14.863594 32 13.5 32L7.5 32 z M 8 35L13 35L13 40L8 40L8 35 z M 20.5 36 A 1.50015 1.50015 0 1 0 20.5 39L41.5 39 A 1.50015 1.50015 0 1 0 41.5 36L20.5 36 z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <span className="h1 font-weight-bold mb-0">
                      {data.rejectedOrders == null ? 0 : data.rejectedOrders}
                    </span>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      الطلبات المرفوضة
                    </h5>
                  </div>
                  <div className="col-4">
                    <svg
                      viewBox="0 0 80 80"
                      width="80"
                      height="80"
                      className="rounded-circle shadow"
                      fill="#f89d54"
                    >
                      <circle cx="40" cy="40" r="38" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="80"
                        height="80"
                      >
                        <path
                          d="M24 4C12.972066 4 4 12.972074 4 24C4 35.027926 12.972066 44 24 44C35.027934 44 44 35.027926 44 24C44 12.972074 35.027934 4 24 4 z M 24 7C28.159946 7 31.91445 8.5434723 34.865234 11.013672L11.013672 34.863281C8.544055 31.912656 7 28.1594 7 24C7 14.593391 14.593385 7 24 7 z M 36.986328 13.134766C39.456525 16.085553 41 19.840056 41 24C41 33.406609 33.406615 41 24 41C19.840597 41 16.087341 39.455948 13.136719 36.986328L36.986328 13.134766 z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <span className="h1 font-weight-bold mb-0">
                      {data.ordersPreparation}
                    </span>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      تجهيز الطلبات
                    </h5>
                  </div>
                  <div className="col-4">
                    <svg
                      viewBox="0 0 80 80"
                      width="80"
                      height="80"
                      className="rounded-circle shadow"
                      fill="#f89d54"
                    >
                      <circle cx="40" cy="40" r="38" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="75"
                        height="75"
                      >
                        <path
                          d="M25 2L25 5L27 5L27 2L25 2 z M 21 3L21 5L23 5L23 3L21 3 z M 11 7C9.5177884 7 8.3845058 7.9301291 7.6972656 9.1367188C7.462762 9.092341 7.2490713 9 7 9C4.8026661 9 3 10.802666 3 13L3 14L1 14L1 15C1 19 4 24 11 24L16.539062 24C16.021062 23.422 15.614609 22.746 15.349609 22L11 22C5.466 22 3.4939375 18.498 3.0859375 16L15 16L15 14L5 14L5 13C5 11.883334 5.8833339 11 7 11C7.2582911 11 7.5053637 11.052509 7.7441406 11.148438L8.7871094 11.568359L9.0820312 10.480469C9.3128196 9.6268859 10.069392 9 11 9C11.512933 9 11.966088 9.1937561 12.330078 9.5214844L13 10.125L13.669922 9.5214844C14.033912 9.1937561 14.487067 9 15 9L15 7C14.254309 7 13.593524 7.2613384 13 7.6171875C12.406476 7.2613384 11.745691 7 11 7 z M 17 7L17 20C17 22.197334 18.802666 24 21 24L27 24C29.197334 24 31 22.197334 31 20L31 7L30 7L17 7 z M 19 9L29 9L29 20C29 21.116666 28.116666 22 27 22L21 22C19.883334 22 19 21.116666 19 20L19 9 z M 1 26L1 28L31 28L31 26L1 26 z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-12">
            <div className="card card-stats mb-4 mb-xl-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <span className="h1 font-weight-bold mb-0">
                      {data.completedOrders}
                    </span>
                    <h5 className="card-title text-uppercase text-muted mb-0">
                      الطلبات منتهية
                    </h5>
                  </div>
                  <div className="col-4">
                    <svg
                      viewBox="0 0 80 80"
                      width="80"
                      height="80"
                      className="rounded-circle shadow"
                      fill="#f89d54"
                    >
                      <circle cx="40" cy="40" r="38" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 32 32"
                        width="80"
                        height="80"
                      >
                        <path
                          d="M16 2.71875L12.5625 5.46875L8.9375 5.3125L8.125 5.25L7.9375 6.03125L7.03125 9.5L4.03125 11.46875L3.375 11.90625L3.65625 12.65625L4.9375 16L3.65625 19.34375L3.375 20.09375L4.03125 20.53125L7.03125 22.5L7.9375 25.96875L8.125 26.75L8.9375 26.6875L12.5625 26.53125L16 29.28125L19.4375 26.53125L23.0625 26.6875L23.875 26.75L24.0625 25.96875L24.96875 22.5L27.96875 20.53125L28.625 20.09375L28.34375 19.34375L27.0625 16L28.34375 12.65625L28.625 11.90625L27.96875 11.46875L24.96875 9.5L24.0625 6.03125L23.875 5.25L23.0625 5.3125L19.4375 5.46875 Z M 16 5.28125L18.46875 7.28125L18.78125 7.53125L19.15625 7.5L22.34375 7.34375L23.125 10.34375L23.21875 10.71875L23.53125 10.9375L26.1875 12.6875L25.0625 15.65625L24.9375 16L25.0625 16.34375L26.1875 19.3125L23.53125 21.0625L23.21875 21.28125L23.125 21.65625L22.34375 24.65625L19.15625 24.5L18.78125 24.46875L18.46875 24.71875L16 26.71875L13.53125 24.71875L13.21875 24.46875L12.84375 24.5L9.65625 24.65625L8.875 21.65625L8.78125 21.28125L8.46875 21.0625L5.8125 19.3125L6.9375 16.34375L7.0625 16L6.9375 15.65625L5.8125 12.6875L8.46875 10.9375L8.78125 10.71875L8.875 10.34375L9.65625 7.34375L12.84375 7.5L13.21875 7.53125L13.53125 7.28125 Z M 21.28125 12.28125L15 18.5625L11.71875 15.28125L10.28125 16.71875L14.28125 20.71875L15 21.40625L15.71875 20.71875L22.71875 13.71875Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="User-Cards layout-spacing row">
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-five">
            <div className="widget-content">
              <div className="header">
                <div className="header-body">
                  <h4> المبيعات و الأرباح </h4>
                </div>
              </div>

              <div className="w-content">
                <div className="">
                  <LineChartOrders data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-five">
            <div className="widget-content">
              <div className="header">
                <div className="header-body">
                  <h4> نسبة الطلبات المكتملة</h4>
                </div>
              </div>

              <div className="w-content">
                <OrderStatusChart data={data} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-five">
            <div className="widget-content">
              <div className="header">
                <div className="header-body">
                  <h4> الطباخين</h4>
                </div>
              </div>

              <div className="w-content">
                <ChartView data={data} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-five">
            <div className="widget-content">
              <div className="header">
                <div className="header-body">
                  <h4> نسبة الطباخين للمدينة </h4>
                </div>
              </div>

              <div className="w-content">
                <PieChartView data={data} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-five">
            <div className="widget-content">
              <div className="header">
                <div className="header-body">
                  <h4>المناديب</h4>
                </div>
              </div>

              <div className="w-content">
                <LineChart data={data} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-five">
            <div className="widget-content">
              <div className="header">
                <div className="header-body">
                  <h4>نسبة المناديب للمدينة </h4>
                </div>
              </div>

              <div className="w-content">
                <DriversRateChart data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-wrapper">
        <div className="footer-section f-section-1">
          <p className="">
            حقوق الطبع والنشر © 2022{" "}
            <a
              target="_blank"
              href="https://matbakh24.com"
              className="text-primary"
            >
              مطبخ ٢٤/٧
            </a>
            ، جميع الحقوق محفوظة
          </p>
        </div>
      </div>
    </>
  );
}
