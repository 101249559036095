import { Link, useLocation } from "react-router-dom";
import CusineMeals from "./CusineMeals";

export default function FoodList() {
  const location = useLocation();
  const row = location.state.row;
  return (
    <>
      <div className="layout-spacing row">
        <div className="col-10">
          <h2 className="textOrange">طعام المزود</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/providers"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>
      <CusineMeals className="container" row={row} />
    </>
  );
}
