import React, { Component, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";


export default function FieldEdit() {
  const location = useLocation();
  const row = location.state.row;
  let [imageState, setImage] = useState(row.image);
  let [nameState, setName] = useState(row.name);
  let [statusState, setStatus] = useState(row.status);
  const navigate = useNavigate();

  function HandleImage(e) {
    setImage(e.target.files[0]);
  }

  const postForm = async (event) => {
    event.preventDefault();
    const formdata = new FormData();
    formdata.append("imageFile", imageState);
    formdata.append("image", imageState);
    formdata.append("name", nameState);
    formdata.append("status", statusState);
    formdata.append("id", row.id);
    //console.log("formdata");

    // for (const value of formdata.values( )) {
    //   console.log(value);
    // }
    // for (const key of formdata.keys( )) {
    //   console.log(key);
    // }



    try {
      const response = await fetch(window.baseurl + "field", {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
      });
      const json = await response.json();
      //console.log(json);
      navigate("/admin/fields");
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <div className="layout-spacing row">
        <div className="col-10">
          <h2 className="textOrange">تحرير الحقول</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/fields"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>

      <div id="flLoginForm" className="col-lg-12 layout-spacing rounded">
        <div className="statbox">
          <div className="widget-content widget-content-area">
            <form onSubmit={postForm}>
              <div className="row">

                <div className="col-md-6 col-12 row">
                  <div className="form-group mb-4 col-12">
                    <label>
                      <span className="text-danger">*</span>الاسم
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      defaultValue={row.name === null ? row.name : row.name.split("大")[0]}
                      required
                      placeholder=" الاسم بالكامل"
                    />
                  </div>

                  <div className="form-group mb-4 col-12">
                    <label>
                      <span className="text-danger">*</span>صورة
                    </label>
                    <input
                      type="file"
                      name="image"
                      className="form-control"
                      id="image"
                      onChange={(e) => { HandleImage(e) }}
                      // value={row.image}
                      placeholder="صورة"
                    />
                  </div>
                  <div className="form-group mb-4 col-12">
                    <label>
                      <span className="text-danger">*</span>حدد الحالة
                      </label>
                    <select
                      className="form-control"
                      onChange={(e) => { statusState(e) }}
                    >
                      <option>حدد الحالة</option>
                      <option value="1">مفعل</option>
                      <option value="0">غير مفعل</option>
                    </select>

                  </div>
                  <span className="form-text text-danger">
                    *الحقول المطلوبة
                  </span>
                </div>

                <div className="col-md-6 col-12">
                  <div className="container-fluid">

                    <img src={window.baseurl + "uploads/" + imageState} width={200} height={200} alt="" className="img-thumbnail" />
                  </div>

                </div>
              </div>
              <Link to="/admin/fields" className="btn btn-danger mt-4 floated">
                إلغاء
              </Link>

              <button
                type="submit"
                className="btn btn-success col-2 mt-4 floated"
                onClick={postForm}
              >
                إنشاء الحساب{" "}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
