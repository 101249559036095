import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "react-tabs/style/react-tabs.css";

export default function OrderDetail() {
  const [order, setOrder] = useState();
  const [market, setMarket] = useState();
  const [items, setItems] = useState();
  const [driver, setDriver] = useState();
  const [user, setUsers] = useState();
  const location = useLocation();
  
  const row = location.state.row;
  const orderStatus = [
    "لم يتم دفع سعر الطلب",
    "في انتظار موافقة المطبخ",
    "رفض الطلب",
    "موافقة علي الطلب",
    "انهاء الطلب من المطبخ",
    "موافقة مندوب علي  الطلب",
    "تم استلام الطلب من المطبخ",
    "تم تسليم الطلب للعميل",
    "تم انهاء الطلب بنجاح",
  ];
  const getOrder = async () => {
    try {
      const response = await fetch(
        window.baseurl + "order/details/" + row?.order?.id,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      const json = await response.json();
      let orderData = json;
      console.log("response");
      console.log(orderData);
      setOrder(orderData.data.order);
      console.log("order");
      console.log(order);
      setMarket(orderData.data.market);
      console.log("marlet");
      console.log(market);
      setItems(orderData.data.orderItems);
      console.log("items");
      console.log(items);
      setDriver(orderData.data.driver);
      console.log("driver");
      console.log(driver);
      setUsers(orderData.data.user);
      console.log("uer");
      console.log(user);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getOrder();
  }, []);

  const showMap = (lat, lng) => {
    window.open("https://maps.google.com?q=" + lat + "," + lng);
  };
  return (
    <>
      <div className="layout-spacing row">
        <div className="col-10">
          <h2>تفاصيل الطلب</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/orders"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>
      <div className="col-lg-12 col-12 layout-spacing">
        <div className="widget-content widget-content-area  ">
          <h3>
            الطلب
            <span className="textOrange"> # {order?.id}</span>
          </h3>
          <div className="col-sm-12 ">
            <div className="item-container col-12">
              <div className="row p-3">
                <div className="col-xl-4 col-lg-6 col-12 mt-2">
                  <label className="item-title">رقم الطلب</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={" # " + order?.id}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">تاريخ الطلب</label>

                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={" " + order?.date?.split("T")[0]}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">وقت الطلب</label>

                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={" " + order?.date?.split("T")[1]}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">حالة الطلب</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={" " + orderStatus[order?.status]}
                      disabled
                    />
                  </div>
                </div>

                {/* <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">طريقة الدفع</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={
                        row.order?.payment == 1
                          ? "الدفع نقدا"
                          : "الدفع الإلكتروني"
                      }
                      disabled
                    />
                  </div>
                </div> */}
                {/* <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">تكلفة التوصيل</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={order?.delivery_price + " ريال "}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">سعر الطلبات</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={
                        Math.round(order?.cart_price * 100) / 100 + " ريال "
                      }
                      disabled
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">كسب المطبخ</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={order?.provider_cartPrice + " ريال "}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">ضريبة</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={order?.tax_price + " ريال "}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">الإجمالي</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={order?.total_price + " ريال "}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                  <label className="item-title">كسب الشركة</label>
                  <div className="item-content">
                    <input
                      className="form-control"
                      type="text"
                      value={
                        order?.cart_price -
                        order?.provider_cartPrice -
                        order?.tax_price +
                        " ريال "
                      }
                      disabled
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-12 layout-spacing">
        <div className="widget-content widget-content-area  ">
          <h3>الأسعار</h3>
          <div className="item-container col-12">
            <div className="row inv--product-table-section">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="">
                      <tr>
                        <th>سعر الطلبات</th>
                        <th>تكلفة التوصيل</th>
                        <th>كسب المطبخ</th>
                        <th>ضريبة</th>
                        <th>كسب الشركة</th>
                        <th>الإجمالي</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        <tr>
                          
                          <td>{Math.round(order?.cart_price * 100) / 100}</td>

                          <td>
                            {Math.round(order?.delivery_price * 100) / 100}
                          </td>
                          <td>
                            {Math.round(order?.provider_cartPrice * 100) / 100}
                          </td>
                          <td>
                            {Math.round(
                              ((order?.cart_price -
                                order?.provider_cartPrice) -
                                0.15) *
                                100
                            ) / 100}
                          </td>
                          <td>
                            {Math.round(
                              (order?.cart_price -
                                order?.provider_cartPrice -
                                (order?.cart_price -
                                  order?.provider_cartPrice -
                                  0.15)) *
                                100
                            ) / 100}
                          </td>
                          <td>{order?.total_price}</td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-12 layout-spacing">
        <div className="widget-content widget-content-area  ">
          <h3>طلب بضاعة</h3>
          <div className="item-container col-12">
            <div className="row inv--product-table-section">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="">
                      <tr>
                        <th>#</th>
                        <th>طلب بضاعة</th>
                        <th>كمية</th>
                        <th>سعر العنصر</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items?.map((item, index) => (
                        <>
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item?.food_name?.split("大")[0]}</td>
                            <td>{item?.quantity}</td>
                            <td>{Math.round(item?.price*
                                100
                            ) / 100} ريال</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-12 layout-spacing">
        <div className="row widget-content widget-content-area">
          <div className="col-6 border-right">
            <h3 className="textOrange">العميل</h3>
            <div className="col-sm-12">
              <div className="item-container">
                <div className="row">
                  <div className="col-xl-6 col-12 mt-2">
                    <label className="item-title">الاسم</label>
                    <div className="item-content">
                      <input
                        className="form-control"
                        type="text"
                        value={user?.fullName}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-12 mt-2">
                    <label className="item-title">رقم الهاتف</label>
                    <div className="item-content">
                      <input
                        className="form-control rtl"
                        dir="rtl"
                        type="text"
                        value={user?.userName}

                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-12 mt-2">
                    <label className="item-title">البريد الإلكتروني</label>
                    <div className="item-content">
                      <input
                        className="form-control"
                        type="text"
                        value={user?.email}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-12 mt-2">
                    <button
                      onClick={(e) => showMap(order?.user_lat, order?.user_lng)}
                      className="item-content btn btn-outline-warning rounded-2 mt-4"
                    >
                      موقع العميل &nbsp; &nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-map-pin"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                        <circle cx="12" cy="10" r="3"></circle>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <h3 className="textOrange">المطبخ</h3>
            <div className="col-sm-12">
              <div className="item-container my-3  col-12">
                <div className="row p-3">
                  <div className="col-xl-6 col-12 mt-2">
                    <label className="item-title">الاسم</label>
                    <div className="item-content">
                      <input
                        className="form-control"
                        type="text"
                        value={market?.title?.split("大")[0]}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-12 mt-2">
                    <label className="item-title">رقم الهاتف</label>
                    <div className="item-content">
                      <input
                        className="form-control"
                        type="text"
                        value={market?.phone}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-12 mt-2">
                    <button
                      onClick={(e) =>
                        showMap(order?.market_lat, order?.market_lng)
                      }
                      className="item-content btn btn-outline-warning rounded-2 mt-4"
                      style={{ borderColor: "#f5a623" }}
                    >
                      موقع المطبخ &nbsp; &nbsp;
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-map-pin"
                      >
                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                        <circle cx="12" cy="10" r="3"></circle>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={driver == null ? "invisible" : "visible"}>
        <div className="col-lg-12 col-12 layout-spacing">
          <div className="widget-content widget-content-area  ">
            <h3 className="textOrange">سائق</h3>
            <div className="col-sm-12 ">
              <div className="item-container my-3">
                <div className="row p-3">
                  <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                    <label className="item-title">الاسم</label>
                    <div className="item-content">
                      <input
                        className="form-control"
                        type="text"
                        value={driver?.name}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-6 col-sm-12 mt-2">
                    <label className="item-title">رقم الهاتف</label>
                    <div className="item-content">
                      <input
                        className="form-control"
                        type="text"
                        value={driver?.mobileNumber}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
