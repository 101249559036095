import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Alerts = () => {
  let [status, setStatus] = useState();
  let [title, setTitle] = useState();
  let [desc, setDesc] = useState();
  let [validTill, setValidTill] = useState();
  console.log(status);
  const postForm = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem("token"));
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        "isSuccess": status === "true" ? true : false,
        "title": title,
        "desc": desc,
        "validTill": validTill
      });

      console.log(raw);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      const response = await fetch(
        "https://dev.webapi.matbakh24.com/admin/home/alert",
        requestOptions
      );
      const json = await response.text();
      if (json==1) {
        toast.success(" تمت إضافة التنبيه بنجاح ", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      console.log(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    <ToastContainer />
    <div id="flLoginForm" className="col-lg-12 layout-spacing">
      <div className="statbox">
        <div className="widget-content widget-content-area">
          <h1 className="my-3">الإعدادات</h1>
          <form onSubmit={postForm}>
            <div className="row col-12 my-3">

              <div className="row col-12 my-3">
                <div className="col-12 col-md-4 mt-2">
                  <div className="input-group">
                    <div className="input-group-prepend"></div>
                    <label className="col-md-12">عنوان التنبيه</label>
                    <input
                      name="alertTitle"
                      type="text"
                      onChange={(e) => setTitle(e.target.value)}
                      className="form-control"
                      placeholder="عنوان التنبيه"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-2">
                  <div className="input-group">
                    <div className="input-group-prepend"></div>
                    <label className="col-md-12">تاريخ انتهاء التنبيه</label>
                    <input
                      name="alertValidTill"
                      type="date"
                      onChange={(e) => setValidTill(e.target.value)}
                      className="form-control"
                      placeholder="تاريخ انتهاء التنبيه "
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4 mt-2">
                  <div className="input-group">
                    <div className="input-group-prepend"></div>
                    <label className="col-md-12">اصدار نسخة اندرويد</label>
                    <select
                      name="alertType"
                      type="text"
                      onChange={(e) => setStatus(e.target.value)}
                      className="form-control"
                      defaultValue={""}
                    >
                      <option value="" disabled>حدد نوع التنبيه</option>
                      <option value="false">تنبيه تحذيري</option>
                      <option value="true">تنبيه ناجح</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2">
                <div className="input-group">
                  <div className="input-group-prepend"></div>
                  <label className="col-md-12">وصف التنبيه</label>
                  <textarea
                    name="alertDescription"
                    style={{ height: "100px" }}
                    type="text"
                    onChange={(e) => setDesc(e.target.value)}
                    className="form-control"
                    placeholder=" وصف التنبيه"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 text-center">
            </div>
          </form>
          <button
            type="submit"
            className="col-md-3 btn btn-primary font-bold"
            onClick={postForm}
          >
            حفظ
          </button>
        </div>
      </div>
    </div>
    </>
  );
}


export default Alerts;