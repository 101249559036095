import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function AddField() {
  const results = (e) => {
    if (e === "OK") {
      toast.success(
        "Thank you for your message. We will get back to you as soon as possible.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else if (e === "imageName") {
      toast.error("الصورة والاسم مطلوب", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "image") {
      toast.error("تحميل الصور", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "name") {
      toast.error("أكتب اسم", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (e === "deleted") {
      toast.error("الرجاء إدخال البريد الإلكتروني الصحيح وكلمة المرور", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [image, setImage] = useState(null);
  const [name, setName] = useState(null);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  function HandleImage(e) {
    setImage(e.target.files[0]);
  }

  const postForm = async (event) => {
    event.preventDefault();
    const formdata = new FormData();
    formdata.append("ImageFile", image);
    formdata.append("name", name);
    formdata.append("status", status);

    if(image ===null || name===null)
    {
      if(image ===null && name===null)
      {
        results("imageName");
      }
      else if(image ===null)
      {
        results("image");
      }
      else if(name ===null)
      {
        results("name");
      }
    }
    try {
      const response = await fetch(window.baseurl + "field", {
        method: "POST",
       headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
      });
      const json = await response.json();
      if (json.resultCode === "200") {
        results("OK");
      } else {
        results("error");
      }
      //console.log(json);
      navigate("/admin/fields");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
    
    <ToastContainer />
      <div className="layout-spacing row">
        <div className="col-10">
          <h2 className="textOrange">أضف الحقول</h2>
        </div>
        <div className="col-2">
          <Link
            to="/admin/fields"
            className="btn btn-outline-primary rounded-circle mx-2"
            title="الرجوع"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </Link>
        </div>
      </div>

      <div id="flLoginForm" className="col-lg-12 layout-spacing rounded">
        <div className="statbox">
          <div className="widget-content widget-content-area">
            <form onSubmit={postForm}>
              <div className="row">
              <div className="form-group mb-4 col-12 col-md-4">
                <label>
                  <span className="text-danger">*</span>الاسم</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  onChange={(e) => {setName(e.target.value)}}
                  required
                  placeholder=" الاسم بالكامل"
                />
              </div>
              <div className="form-group mb-4 col-12 col-md-4">
                <label>
                  <span className="text-danger">*</span>صورة</label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  id="image"
                  onChange={(e) => {HandleImage(e)}}
                  placeholder="صورة"
                />
              </div>
              <div className="form-group mb-4 col-12 col-md-4">
                <label>
                  <span className="text-danger">*</span>حدد الحالة</label>
                  <select
                      className="form-control"
                      onChange={(e) => { setStatus(e) }}
                    >
                      <option>حدد الحالة</option>
                      <option value="1">مفعل</option>
                      <option value="0">غير مفعل</option>
                    </select>

              </div>
              </div>
              <span id="emailHelp" className="form-text text-danger">
                *الحقول المطلوبة
              </span>
              <Link to="/admin/fields" className="btn btn-danger mt-4 floated">
                إلغاء
              </Link>

              <button
                type="submit"
                className="btn btn-success col-2 mt-4 floated"
              onClick={postForm}
              >
                إنشاء الحساب{" "}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
