import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { useEffect, useState } from "react";

export default function ImagesGallery({ images }) {
  const [data, setData] = useState();
  useEffect(() => {
    //console.log(images);
    for (let i = 0; i < images.length; i++) {
      if (
        images[i].images == null ||
        images[i].images == undefined ||
        images[i].images == ""
      ) {
        images[i].images = "/no-image.png";
      }
    }
    setData(
      images
        .filter((e) => e != null)
        .map((e, index) => {
          const item = {
            original: window.baseurl + "uploads/" + e.images,
            Key: index,
            mouseWheel: "true",
            scrollable: "true",
            originalClass: "original",
            showFullscreenButton: "false",
            showPlayButton: "False",
            lazyLoad: "true",
            showBullets: "true",
            description: e.description,
          };

          return item;
        })
    );
  }, []);

  return data ? <ImageGallery items={data} /> : null;
}
