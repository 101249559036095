import React from 'react';

const FoodEdit = () => {
    return (
        <div>
            <h1>Food Edit</h1>
        </div>
    );
}

export default FoodEdit;
