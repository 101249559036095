// import React, { useState } from "react";
import React from "react";
//import { Link, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Pagination } from "@mui/material";
import Loading from "../Components/preLoading";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class Foods extends React.Component {
  componentDidMount() {
    this.getData();
  }


  DeleteAdmin = async (e) => {
    // console.log(e);
    const formData = new FormData();
    formData.append("UserId", e);
    try {
      const response = await fetch(window.baseurl + "foods/" + e, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      this.data = json;
      if (response.ok == "true")
        response.json().then((json) => {
          this.getData();
          if (json.resultCode == "400") {
            toast.success("تم حذف المستخدم بنجاح", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        });

      //console.log(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };
  onSubmit = (e) => {
    confirmAlert({
      title: "تأكيد للحذف",
      message: "هل أنت متأكد من حذف الطعام؟",
      buttons: [
        {
          label: "نعم ، احذف",
          onClick: () => this.DeleteAdmin(e),
        },
        {
          label: "رقم",
        },
      ],
    });
  };

  search = "";
  pageCount = 10;
  constructor(props) {
    super(props);
    this.state = {
      city: "الكل",
      page: 1,
      filteredData: null,
    };
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  updateFood = async (status, id) => {
    const jsonRequest = {};
    var form = new FormData();
    form.append("id", id.toString());
    form.append("status", status.toString());

    // console.log(jsonRequest);
    try {
      const response = await fetch(window.baseurl + "food/update-status", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: form,
      });
      const json = await response.json();
      // console.log(json);
      this.getData();
    } catch (error) {
      console.error(error);
    }
  };

  onPaginationChange(event, value) {
    this.setState({ page: value });
  }

  paginatedList() {
    return this.state.filteredData.slice(
      (this.state.page - 1) * this.pageCount,
      this.state.page * this.pageCount
    );
  }

  data = null;
  searchData(search) {
    this.search = search;
    var filtered =
      search === ""
        ? this.data
        : this.data.filter(
          (e) =>
            e.food.marketName?.includes(search) || e.food.name?.includes(search)
        );
    this.setState({ filteredData: filtered });
  }

  getData = async () => {
    try {
      const response = await fetch(window.baseurl + "admin/get-foods", {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      console.log(json);
      this.data = json;
      this.searchData(this.search);
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <>
        <ToastContainer />
        <div className="py-4 px-5">
          <h1>الوجبات </h1>
        </div>

        <div className="layout-px-spacing">
          <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div className="widget-content widget-content-area br-6">
                <div className="row">
                  <div className="col-md-4">
                    <label asp-for="Name"> بحث</label>
                    <input
                      type="text"
                      onChange={(e) => this.searchData(e.target.value)}
                      className="form-control"
                      id="name"
                      placeholder=" الاسم ، رقم الهاتف ، الايميل"
                    />
                  </div>
                </div>

                <div className="table-responsive mb-4 mt-4">
                  {this.data === null ? (
                    <Loading />
                  ) : (
                    <div>
                      <table
                        id="zero-config"
                        className="table table-hover filesTable"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">م</th>
                            <th scope="col">تاريخ</th>
                            <th scope="col">اسم الوجية</th>
                            <th scope="col">مزود الخدمة</th>
                            <th scope="col">سعر الطباخة</th>
                            <th scope="col">سعر المستخدم</th>
                            <th scope="col">الصورة</th>
                            <th scope="col">مفعل</th>
                            <th scope="col">إجراء</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        {this.paginatedList().map((e, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{this.state.filteredData.indexOf(e) + 1}</td>
                              <td>
                                {e?.food.createdAt.split("T")[1]}
                                <br />
                                {e?.food.createdAt.split("T")[0]}
                              </td>
                              <td>{e?.food.name?.split("大")[0]}</td>
                              <td>{e?.food?.marketName?.split("大")[0]}</td>
                              <td>
                                {/* {e?.food.price - e?.food.price * 0.25 + " SR"} */}
                                {e?.food.provider_price + " SR"}

                              </td>
                              <td>{e?.food.price + " SR"}</td>
                              <td>
                                <div className="d-flex">
                                  <div className="usr-img-frame mr-2 rounded-circle">
                                    {
                                      <img
                                        className="img-fluid rounded-circle"
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={
                                          window.baseurl +
                                          "uploads/" +
                                          e.provider?.image
                                        }
                                        title={
                                          e?.provider?.title
                                        }
                                        onError={(e) => (
                                          (e.target.onError = null),
                                          ((e.target.src =
                                            "../img/no-image.png"),
                                            (e.target.title = "Image not found"))
                                        )}
                                        alt="img"
                                      />
                                    }
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  onClick={(ev) => {
                                    var status = e.food.status === 0 ? 1 : 0;
                                    this.updateFood(status, e.food.id);
                                  }}
                                  className="row cursor-pointer"
                                >
                                  <h6
                                    role="button"
                                    className={
                                      e.food.status === 0 ? "pend" : "publish"
                                    }
                                  >
                                    مفعل
                                  </h6>
                                  <h6
                                    role="button"
                                    className={
                                      e.food.status === 1 ? "pend" : "disable"
                                    }
                                  >
                                    غير مفعل
                                  </h6>
                                </div>
                              </td>
                              <td>
                                <Link
                                  className="btn btn-outline-primary rounded-circle mx-2"
                                  to={"/admin/food/detail"}
                                  state={{
                                    row: e,
                                  }}
                                  title="التفاصيل"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-eye"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </Link>
                                <button
                                  className="btn btn-outline-danger rounded-circle mx-2"
                                  data-toggle="modal"
                                  data-target="#DeleteModal"
                                  title="الصلاحيات"
                                  state={{
                                    e,
                                  }}
                                  onClick={() => this.onSubmit(e.food.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-trash"
                                  >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                        <tfoot>
                          <tr>
                            <th scope="col">م</th>
                            <th scope="col">تاريخ</th>
                            <th scope="col">اسم الوجية</th>
                            <th scope="col">مزود الخدمة</th>
                            <th scope="col">سعر الطباخة</th>
                            <th scope="col">سعر المستخدم</th>
                            <th scope="col">الصورة</th>
                            <th scope="col">مفعل</th>
                            <th scope="col">إجراء</th>
                            <th scope="col"></th>
                          </tr>
                        </tfoot>
                      </table>
                      <div dir="ltr" className="mx-auto mt-[40px]">
                        <Pagination
                          count={Math.ceil(
                            this.state.filteredData.length / this.pageCount
                          )}
                          onChange={this.onPaginationChange}
                          variant="outlined"
                          color="primary"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
