import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MultiSelectAll from "../Components/MultiSelectAll";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Notify.css";
import { clear } from "@testing-library/user-event/dist/clear";

var selectedUsersIds = [];
export default function Notify() {
  let navigate = useNavigate();
  var [Users, setUsers] = useState(null);
  var [message, setMessage] = useState(null);
  var [usertype, setUserType] = useState(null);
  var [errorMsg, setErrorMsg] = useState(null);
  var [errorUser, setErroruser] = useState();
  var [selectedUsers, setSelectedUsers] = useState([]);
  console.log(usertype);
  // drivers
  // providers

  const getUserData = (json) => {
    var res = [];
    json.forEach((e) => {
      res.push({
        id: e.id,
        value: e.id,
        label: e.fullName,
      });
    });
    let values = Array.from(res, ({ id }) => id);
    // console.log(values);
    return res;
  };

  //Getting Users Id's
  const getUsers = async () => {
    try {
      const response = await fetch(window.baseurl + "admin/get-users", {
        method: "Get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      getUserData(json);

      setUsers(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  // Getting Selected Users Id's
  const getUsersId = (json) => {
    console.log("userId called");
    console.log(json);
    var res = [];
    json.forEach((e) => {
      res.push({
        id: e.id,
        value: e.id,
        label: e.fullName,
      });
    });
    let values = Array.from(res, ({ id }) => id);
    //Clear Array before adding new values
    selectedUsersIds = [];
    //Add new values
    selectedUsersIds = values;
    console.log(values);
    return res;
  };

  const getDriverId = (json) => {
    console.log("userId called");
    console.log(json);
    var res = [];
    json.forEach((e) => {
      res.push({
        id: e?.user?.id,
        value: e.id,
        label: e.fullName,
      });
    });
    let values = Array.from(res, ({ id }) => id);
    //Clear Array before adding new values
    selectedUsersIds = [];
    //Add new values
    selectedUsersIds = values;
    console.log(values);
    return res;
  };

  //Getting user id if user type is selected
  if (usertype !== null) {
    //Getting Users Id's
    const getUsers = async () => {
      try {
        const response = await fetch(window.baseurl + "admin/get-users", {
          method: "Get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const json = await response.json();
        console.log(json);
        getUsersId(json);
        return json;
      } catch (error) {
        console.error(error);
      }
    };

    //Getting Drivers Id's
    const getDrivers = async () => {
      try {
        const response = await fetch(window.baseurl + "admin/get-drivers", {
          method: "Get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const json = await response.json();
        //console.log(json);
        getDriverId(json);
        return json;
      } catch (error) {
        console.error(error);
      }
    };

    //Getting Markets Id's
    const getMarket = async () => {
      try {
        const response = await fetch(window.baseurl + "admin/get-markets", {
          method: "Get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        const json = await response.json();
        getDriverId(json);

        return json;
      } catch (error) {
        console.error(error);
      }
    };

    if (usertype == "users") {
      var value = getUsers();
      let values = Array.from(value, ({ id }) => id);
    //Clear Array before adding new values
    selectedUsersIds = [];
    //Add new values
    selectedUsersIds = values;
    }

    if (usertype == "drivers") {
      var value = getDrivers();
      let values = Array.from(value, ({ id }) => id);
    //Clear Array before adding new values
    selectedUsersIds = [];
    //Add new values
    selectedUsersIds = values;
    }
    if (usertype == "providers") {
      var value = getMarket();
      let values = Array.from(value, ({ id }) => id);
    //Clear Array before adding new values
    selectedUsersIds = [];
    //Add new values
    selectedUsersIds = values;
    }
  }

  //Calling Send Notification Api
  const sendNotify = async () => {
    try {
      if (message === null) {
        setErrorMsg("الرجاء إدخال الرسالة");
        toast.error("الرجاء إدخال الرسالة", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else if (Users === null || usertype === null) {
        setErroruser("الرجاء إختيار المستخدمين");
        toast.error("الرجاء إختيار المستخدمين", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      else {


        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("token")
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          userIdz: selectedUsersIds,
          body: message,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        var response = fetch(
          window.baseurl + "api/Notifs/sendnotification",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {

            console.log(result);
            if (result === "Notification sent successfly") {
              toast.success("تم إرسال الإشعار بنجاح", {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate(1)
            } else {
              toast.error("حدث خطأ ما", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => {
            console.log("error", error);
            toast.error(error, {
              position: toast.POSITION.TOP_RIGHT,
            });
            console.log(error);
          });
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Assigning the selected users to the selectedUsersIds array
  const handleChange = (value) => {
    let values = Array.from(value, ({ id }) => id);
    //Clear Array before adding new values
    selectedUsersIds = [];
    //Add new values
    selectedUsersIds = values;
    console.log(selectedUsersIds);
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <ToastContainer />

      <div className="layout-spacing">
        <div className="col-">
          <h2 className="textOrange">إشعار لمستخدم واحد</h2>
        </div>
        <div className="statbox">
          <div className="widget-content widget-content-area">
            {/* <form onSubmit={postForm}> */}
            <form>
              <div className="py-2 row">
                <div className="col-9">

                  <textarea
                    type="text"
                    required={true}

                    className={errorMsg !== null ? "form-control is-invalid" : "form-control"}
                    onClick={() => { setErrorMsg(null) }}
                    placeholder="رسالة إعلامية"
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                  <span className="text-danger">{errorMsg}</span>
                </div>
                <div className="col-3">
                  <div
                   className={errorUser !== null ? " is-invalid" : ""}
                  >
                    {Users && (
                      <MultiSelectAll
                        classNames={{
                          select: "form-control",
                        }}
                        handleChange={handleChange}
                        data={getUserData(Users)}
                        style={{
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    )}
                  </div>
                  <div className=" py-4">
                    <select
                    className="form-control"
                      onChange={(e) => {
                        setUserType(e.target.value);
                      }}
                    >
                      <option disabled selected>حدد نوع المستخدم</option>
                      <option value={"users"}>المستخدمين</option>
                      <option value={"drivers"}> السائقين </option>
                      <option value={"providers"}> مطابخ </option>
                    </select>
                  </div>
                  <span className="text-danger">{errorUser}</span>
                </div>
              </div>
            </form>
            <button
              onClick={(e) => {
                sendNotify();
              }}
              type="submit"
              className="btn btn-success col-2"
            >
              يرسل
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
